import { IUserPermissions } from 'types/IUser';

export interface IRole {
  id: number;
  name: string;
  userType: string;
  permissions: IUserPermissions[];
}

export interface IRoleResponse {
  pageNumber: number;
  pageSize: number;
  totalPage: number;
  totalRecords: number;
  success: boolean;
  message: string;
  resource: IRole[];
}

export class NullPermissions implements IUserPermissions {
  public id = null;
  public roleId = null;
  public moduleId = null;
  public module = {
    id: null,
    name: ''
  };
  public read = false;
  public edit = false;
  public delete = false;
  public create = false;
}

export class NullRole implements IRole {
  public id = null;
  public name = '';
  public userType = '';
  public permissions = [new NullPermissions()];
}
