import { ITableHeader } from 'components/widgets/tables/tableBodyTypes';
import { ReactElement } from 'react';

export enum EModalIcon {
  WARNING = 'warning'
}

export interface BasicTableProps {
  tableHeaders: ITableHeader[];
  handleSortAndSearch: Function;
  isLoading: boolean;
  count: number;
  tableConfig?: ITableConfig;
  tableBody: any;
  handleSearchChange: any;
  handleSortChange: any;
  handleFilterChange: any;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  rowsPerPage: any;
  page: any;
}

export interface IModalConfig {
  showModal: boolean;
  title: string;
  icon: EModalIcon;
  message: string;
  disabled: boolean;
  showOptions: {
    timesButton: boolean;
    desactiveButton: boolean;
    cancelButton: boolean;
  };
  functions: {
    mainAction: Function;
    hideModal: Function;
    showModal: Function;
  };
}
export interface ITableConfig {
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  showOptions?: {
    searchInput: boolean;
    sortInput: boolean;
    filterInput?: boolean;
    pagination?: boolean;
  };
  sortOptions?: {
    label: string;
    value: SortType;
  }[];
  filterInputConfig?: {
    filterLabel: string;
    filterProperty: string;
    options: {
      label: string;
      value: string;
    }[];
  };
  placeHolderText?: {
    search: string;
  };
  BeginningButtonConfig?: {
    title: string;
    action: Function;
    icon?: ReactElement<any, any>;
  };
  ModalConfig?: IModalConfig;
  AuxiliarModalConfig?: IModalConfig;
  minWidthContainer?: number;
}

export enum SortType {
  DATE = 'StartDate',
  DATE_DESC = 'StartDate desc',
  DATE_ASC = 'StartDate asc',
  CREATED_DATE = 'Job.CreatedAt',
  CREATED_DATE_ASC = 'Job.CreatedAt asc',
  CREATED_DATE_DESC = 'Job.CreatedAt desc',
  CLIENT_NAME_ASC = 'Name asc',
  NAME_ASC = 'Name FamilyName asc',
  NAME = 'Name FamilyName',
  NAME_DESC = 'Name FamilyName desc',
  JOB_POSITION = 'JobPosition',
  JOB_POSITEON_DESC = 'JobPosition desc',
  JOB_ID_DESC = 'JobId desc',
  JOB_ID_ASC = 'JobId asc',
  JOB_ID = 'JobId',
  ROLE_ASC = 'Role asc',
  ROLE = 'Role',
  TOTAL_EVENTS_ASC = 'TotalEvents asc',
  TOTAL_EVENTS_DESC = 'TotalEvents desc',
  EVENT_JOB_NUMBER = 'JobId ChildId',
  EVENT_JOB_NUMBER_ASC = 'JobId ChildId asc',
  EVENT_JOB_NUMBER_DESC = 'JobId desc,ChildId',
  EVENT_NAME = 'Name',
  EVENT_NAME_DESC = 'Name desc',
  EVENT_STAGE = 'Stage',
  EVENT_STAGE_DESC = 'Stage desc',
  EVENT_TYPE_ASC = 'Type asc',
  EVENT_TYPE_DESC = 'Type desc',
  BUDGET_TEMPLATE_NAME = 'Name',
  BUDGET_TEMPLATE_NAME_ASC = 'Name asc',
  BUDGET_TEMPLATE_NAME_DESC = 'Name desc'
}
