import { fetchAPI } from 'services/http';
import {
  STORAGE_UPLOAD_URL,
  STORAGE_LOAD_URL,
  ATTACHMENT_STORAGE_UPLOAD_URL,
  ATTACHMENT_STORAGE_LOAD_URL
} from 'config/endpoints';
import { IAttachmentLoadReponse } from 'types/IEvent';
import { IFetchOptions, EMethods } from './types';

const optionsGetUploadPresignedUrl: IFetchOptions = {
  method: EMethods.PUT,
  URL: STORAGE_UPLOAD_URL
};

const optionsGetLoadPresignedUrl: IFetchOptions = {
  method: EMethods.GET,
  URL: STORAGE_LOAD_URL
};

const getUploadPresignedUrl = (params: string, body: any, headers: any): Promise<string> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUploadPresignedUrl,
    params,
    headers,
    body
  };
  return fetchAPI(newOptions);
};

const getUserImageUrl = (params: string): Promise<string> => {
  const newOptions: IFetchOptions = {
    ...optionsGetLoadPresignedUrl,
    params
  };
  return fetchAPI(newOptions);
};

const optionsGetUploadPresignedUrlAttachment: IFetchOptions = {
  method: EMethods.PUT,
  URL: ATTACHMENT_STORAGE_UPLOAD_URL
};

const optionsGetLoadPresignedUrlAttachment: IFetchOptions = {
  method: EMethods.GET,
  URL: ATTACHMENT_STORAGE_LOAD_URL
};

const getUploadPresignedUrlAttachment = (params: string, body: any, headers: any): Promise<string> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUploadPresignedUrlAttachment,
    params,
    headers,
    body
  };
  return fetchAPI(newOptions);
};

const getAttachmentUrl = (params: any): Promise<IAttachmentLoadReponse> => {
  const newOptions: IFetchOptions = {
    ...optionsGetLoadPresignedUrlAttachment,
    params
  };
  return fetchAPI(newOptions);
};

export { getUserImageUrl, getUploadPresignedUrl, getUploadPresignedUrlAttachment, getAttachmentUrl };
