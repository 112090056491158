import { useEffect, useState } from 'react';
import { IRole, IRoleResponse } from 'types/IRole';
import { getRolesByUserType } from 'services/http';

export const useGetRolesByUserType = (type: string): { roles: IRole[]; isLoading: boolean } => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRoles = async () => {
    try {
      setIsLoading(true);
      const { resource }: IRoleResponse = await getRolesByUserType({
        Type: type
      });
      setRoles(resource);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);
  return { roles, isLoading };
};
