import moment from 'moment';

export const getRoomQuantitiesByDates = (startDate: string, endDate: string, substractLastDay: boolean): string[] => {
  const dates: string[] = [];
  const currDate = moment(startDate).add(-1, 'days').startOf('day');
  let lastDate = moment(endDate);
  if (substractLastDay) lastDate = lastDate.add(-1, 'days').startOf('day');
  if (currDate && lastDate) {
    while (currDate.add(1, 'days').diff(lastDate) <= 0) {
      const currentDateToString = moment(currDate.clone().toDate()).format();
      dates.push(currentDateToString);
    }
  }
  return dates;
};
