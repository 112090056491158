import { fetchAPI } from 'services/http';
import {
  EVENTS_URL,
  EVENT_STAFFS,
  EVENTS_URL_BY_STATUS,
  EVENTS_MEETING_TYPE,
  CHILD_EVENTS_URL,
  EVENT_STAFFS_ALL,
  EVENTS_SCOPES,
  EVENTS_MEETING_SCOPE,
  EVENTS_BUDGET_BY_EVENT_URL,
  EVENT_BUDGET_URL,
  EVENT_SIGNED_DOCS_BY_EVENT_URL,
  EVENT_SIGNED_DOCS_URL,
  EVENTS_VIRTUAL_COMPONENTS,
  EVENTS_HYBRID_COMPONENTS,
  EVENTS_GET_NUMBER_OF_ATTENDEES,
  EVENTS_GET_INCOME_TYPES,
  EVENTS_GET_PAYMENT_TYPES,
  EVENTS_GET_RECONCILIATION_ON_DUE,
  EVENTS_GET_STATUSES,
  EVENT_WORKING_BUDGET_BY_ID,
  EVENT_SIMPLE_RECON_BY_ID,
  EVENT_WORKING_CHANGE_ORDER_COMPONENT_TYPES,
  EVENT_PANDA_DOC_DOWNLOAD_PDF,
  EVENT_PANDA_DOC_DELETE,
  EVENT_RECONCILIATION_BY_ID,
  EVENT_RECONCILIATION_DOWNLOAD_PDF,
  EVENT_PANDA_DOC_SEND_EMAIL,
  ATTACHMENT_FILES_URL,
  EVENT_WORKING_CHANGE_ORDER_DELETE,
  EVENT_GET_REGIONS,
  EVENTS_INACTIVE_URL,
  EVENT_RECONCILIATION_EXCEL_DOWNLOAD
} from 'config/endpoints';
import { TABLE_CONFIG } from 'config/constants';
import {
  IEvent,
  IEventBudgetResponse,
  IEventsResponse,
  IEventsSignedDocsResponse,
  IMeetingScopeResponse,
  IMeetingTypesByScopeResponse,
  IVirtualComponentResponse,
  IHybridComponentResponse,
  IScopeResponse,
  IEventWorkingBudget,
  EComponentType,
  IEventReconciliation,
  IEventSimpleRecon
} from 'types/IEvent';
import { getToken } from 'utils/authentication';
import { SortType } from 'types/IBasicTable';
import { IFetchOptions, EMethods } from './types';

enum EventStatuses {
  INBOX = 'inbox'
}

const HTTP_ERRORS = {
  NOT_FOUND: 'Error: Request failed with status code 404'
};

interface IEventsParams {
  PageNumber: number;
  PageSize: number;
  OrderBy?: string;
  Search?: string;
  userId: number | string;
  eventId?: number | string;
}

interface IEventsParamsByUsers {
  PageNumber?: number;
  PageSize?: number;
  OrderBy?: string[];
  Search?: string;
  Inactive?: string;
}

interface IEventsParamsByClients {
  PageNumber?: number;
  PageSize?: number;
  OrderBy?: string[];
  Search?: string;
  userId?: number | string;
  Inactive?: boolean | string;
}

interface IEventsParamsByStatus {
  PageNumber?: number;
  PageSize?: number;
  status?: EventStatuses;
  OrderBy?: SortType;
  Search?: string;
  userId?: number | string;
  eventId?: number | string;
}

const initialParams: IEventsParams = {
  PageNumber: 0,
  PageSize: TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS[0],
  userId: null,
  OrderBy: 'JobId'
};

const optionsGetEvents: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_URL,
  params: initialParams
};

const getEvents = (params?: IEventsParams): Promise<IEventsResponse> => {
  if (params) {
    const newOptions: IFetchOptions = {
      ...optionsGetEvents,
      params: {
        ...params,
        OrderBy: params.OrderBy || 'JobId'
      }
    };
    return fetchAPI(newOptions);
  }
  return fetchAPI(optionsGetEvents);
};

const initialParamsByStatus: IEventsParamsByStatus = {
  PageNumber: 0,
  PageSize: TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS[0],
  status: EventStatuses.INBOX,
  userId: null,
  OrderBy: SortType.JOB_ID
};

const optionsGetEventsByStatus: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_URL_BY_STATUS,
  params: initialParamsByStatus
};

const getEventsByStatus = (params?: IEventsParamsByStatus): Promise<IEventsResponse> => {
  if (params) {
    const newOptions: IFetchOptions = {
      ...optionsGetEventsByStatus,
      params
    };
    return fetchAPI(newOptions);
  }
  return fetchAPI(optionsGetEventsByStatus);
};

const optionsGetEventsById: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_URL,
  params: initialParamsByStatus,
  paramInUrlLine: true
};
const getEventInformationById = (params: any): Promise<IEvent> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventsById,
    params
  };
  return fetchAPI(newOptions);
};

const optionsGetEventAttachFilesById: IFetchOptions = {
  method: EMethods.GET,
  URL: ATTACHMENT_FILES_URL,
  paramInUrlLine: true
};
const getEventAttachFilesById = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventAttachFilesById,
    params
  };
  return fetchAPI(newOptions);
};

const optionsGetEventBudgetInformationById: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_BUDGET_BY_EVENT_URL
};
const getEventBudgetInformationById = (eventId: number | string): Promise<IEventBudgetResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventBudgetInformationById,
    params: eventId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetEventWorkingBudgetInformationById: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_WORKING_BUDGET_BY_ID
};
const getEventWorkingBudgetInformationById = (eventId: number | string): Promise<IEventWorkingBudget> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventWorkingBudgetInformationById,
    params: eventId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetEventSimpleReconInformationById: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_SIMPLE_RECON_BY_ID
};
const getEventSimpleReconInformationById = (eventId: number | string): Promise<IEventSimpleRecon> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventSimpleReconInformationById,
    params: eventId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetEventReconciliationInformationById: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_RECONCILIATION_BY_ID
};
const getEventReconciliationInformationById = (eventId: number | string): Promise<IEventReconciliation> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventReconciliationInformationById,
    params: eventId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetEventReconciliationDownloadPDF: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_RECONCILIATION_DOWNLOAD_PDF
};
const getEventReconciliationDownloadPDF = (reconciliationId: number | string, isByClient: boolean): Promise<any> =>
  fetch(
    `${optionsGetEventReconciliationDownloadPDF.URL}?reconciliationid=${reconciliationId}&isbyclient=${isByClient}`,
    {
      method: optionsGetEventReconciliationDownloadPDF.method,
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${getToken()}`
      }
    }
  );

const optionsGetEventScopes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_SCOPES
};

const getEventScopes = (): Promise<string[]> => fetchAPI(optionsGetEventScopes);

const optionsGetEventScopesDetail: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_SCOPES
};

const getEventScopesDetail = (): Promise<IScopeResponse[]> => fetchAPI(optionsGetEventScopesDetail);

const optionsGetMeetingTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_MEETING_TYPE
};

const getMeetingTypes = (): Promise<IMeetingTypesByScopeResponse[]> => fetchAPI(optionsGetMeetingTypes);

const optionsGetMeetingTypesByScope: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_MEETING_TYPE,
  paramInUrlLine: true
};

const getMeetingTypesByScope = (currentScopeId?: number): Promise<IMeetingTypesByScopeResponse[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetMeetingTypesByScope,
    params: currentScopeId
  };
  return fetchAPI(newOptions);
};

const optionsGetVirtualComponents: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_VIRTUAL_COMPONENTS,
  paramInUrlLine: true
};

const getVirtualComponents = (currentScopeId?: number): Promise<IVirtualComponentResponse[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetVirtualComponents,
    params: currentScopeId
  };
  return fetchAPI(newOptions);
};

const optionsGetHybridComponents: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_HYBRID_COMPONENTS,
  paramInUrlLine: true
};

const getHybridComponents = (currentScopeId?: number): Promise<IHybridComponentResponse[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetHybridComponents,
    params: currentScopeId
  };
  return fetchAPI(newOptions);
};

const optionsUpdateInboxEvent: IFetchOptions = {
  method: EMethods.PUT,
  URL: EVENTS_URL,
  paramInUrlLine: true
};

const updateInboxEvent = (body: any): Promise<IEvent> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateInboxEvent,
    body
  };
  return fetchAPI(newOptions);
};

const optionsAddEventStaff: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_STAFFS
};

const addEventStaff = (body: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsAddEventStaff,
    body
  };
  return fetchAPI(newOptions);
};

const optionsAddEvent: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENTS_URL
};

const addEvent = (body: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsAddEvent,
    body
  };
  return fetchAPI(newOptions);
};

const optionsDeleteEventStaff: IFetchOptions = {
  method: EMethods.DELETE,
  URL: EVENT_STAFFS
};

const deleteEventStaff = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteEventStaff,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};
const optionsCreateChildIEvent: IFetchOptions = {
  method: EMethods.POST,
  URL: CHILD_EVENTS_URL
};

const createChildIEvent = (body: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsCreateChildIEvent,
    body
  };
  return fetchAPI(newOptions);
};

const optionsDeleteAllEventStaffs: IFetchOptions = {
  method: EMethods.DELETE,
  URL: EVENT_STAFFS_ALL
};

const deleteAllEventStaffs = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteAllEventStaffs,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetEventMeetingScopeByMeetingType: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_MEETING_SCOPE
};

const getEventMeetingScopeByMeetingType = (meetingTypeId: number): Promise<IMeetingScopeResponse[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetEventMeetingScopeByMeetingType,
    params: meetingTypeId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsUpdateEventBudget: IFetchOptions = {
  method: EMethods.PUT,
  URL: EVENT_BUDGET_URL
};

const updateEventBudget = (eventSegment: IEventBudgetResponse): Promise<IEventBudgetResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateEventBudget,
    body: eventSegment
  };
  return fetchAPI(newOptions);
};

const optionsEventSignedDocsByEvent: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_SIGNED_DOCS_BY_EVENT_URL
};

const eventSignedDocsByEvent = (params: string): Promise<IEventsSignedDocsResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsEventSignedDocsByEvent,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsSendDocumentViaEmail: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_PANDA_DOC_SEND_EMAIL
};

const sendDocumentViaEmail = (params: any): Promise<IEventsSignedDocsResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsSendDocumentViaEmail,
    paramInUrlLine: true,
    params
  };
  return fetchAPI(newOptions);
};

const optionsDownloadDocumentProposalPDF: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_PANDA_DOC_DOWNLOAD_PDF
};

const downloadDocumentProposalPDF = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsDownloadDocumentProposalPDF,
    params,
    paramInUrlLine: true
  };
  return fetch(`${newOptions.URL}?${params}`, {
    method: newOptions.method,
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${getToken()}`
    }
  });
};

const optionsDeleteDocumentProposalPDF: IFetchOptions = {
  method: EMethods.DELETE,
  URL: EVENT_PANDA_DOC_DELETE
};

const deleteDocumentProposalPDF = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteDocumentProposalPDF,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsEventSignedDocumentsPDFCreation: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_SIGNED_DOCS_URL
};

const eventSignedDocumentsPDFCreation = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsEventSignedDocumentsPDFCreation,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetChangeOrderComponentTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_WORKING_CHANGE_ORDER_COMPONENT_TYPES
};

const getChangeOrderComponentTypes = (): Promise<EComponentType[]> => fetchAPI(optionsGetChangeOrderComponentTypes);

const optionsDeleteChangeOrder: IFetchOptions = {
  method: EMethods.DELETE,
  URL: EVENT_WORKING_CHANGE_ORDER_DELETE,
  paramInUrlLine: true
};

const deleteChangeOrderById = (params): Promise<EComponentType[]> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteChangeOrder,
    params
  };
  return fetchAPI(newOptions);
};

const optionsGetNumberOfAttendees: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_GET_NUMBER_OF_ATTENDEES
};
const getNumberOfAttendees = (): Promise<string[]> => fetchAPI(optionsGetNumberOfAttendees);

const optionsGetIncomeTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_GET_INCOME_TYPES
};
const getIncomeTypes = (): Promise<string[]> => fetchAPI(optionsGetIncomeTypes);

const optionsGetReconciliationOnDue: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_GET_RECONCILIATION_ON_DUE
};
const getReconciliationOnDue = (): Promise<string[]> => fetchAPI(optionsGetReconciliationOnDue);

const optionsGetPaymentTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_GET_PAYMENT_TYPES
};
const getPaymentTypes = (): Promise<string[]> => fetchAPI(optionsGetPaymentTypes);

const optionsGetEventStatuses: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENTS_GET_STATUSES
};
const getEventStatuses = (): Promise<string[]> => fetchAPI(optionsGetEventStatuses);

const optionsCreateAttachmentFile: IFetchOptions = {
  method: EMethods.POST,
  URL: ATTACHMENT_FILES_URL
};
const createAttachmentFile = (body): Promise<any[]> => {
  const newOptions: IFetchOptions = {
    ...optionsCreateAttachmentFile,
    body,
    headers: {
      authorization: `Bearer ${getToken()}`
    }
  };
  return fetchAPI(newOptions);
};

const optionsDownloadReconciliationExcel: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_RECONCILIATION_EXCEL_DOWNLOAD
};

const downloadReconciliationExcel = (eventId): Promise<any> =>
  fetch(`${optionsDownloadReconciliationExcel.URL}?eventId=${eventId}`, {
    method: optionsGetEventReconciliationDownloadPDF.method,
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Bearer ${getToken()}`
    }
  });

const optionsInactiveAttachmentFile: IFetchOptions = {
  method: EMethods.PUT,
  URL: ATTACHMENT_FILES_URL
};
const inactiveAttachmentFile = (body): Promise<any[]> => {
  const newOptions: IFetchOptions = {
    ...optionsInactiveAttachmentFile,
    body
  };
  return fetchAPI(newOptions);
};

const optionsGetEventRegions: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_GET_REGIONS
};

const getEventRegions = (): Promise<string[]> => fetchAPI(optionsGetEventRegions);

const optionsInactiveEvent: IFetchOptions = {
  method: EMethods.DELETE,
  URL: EVENTS_INACTIVE_URL
};

const inactiveEvent = (params: any): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsInactiveEvent,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

export {
  EventStatuses,
  getEvents,
  getEventsByStatus,
  getEventInformationById,
  createChildIEvent,
  optionsGetMeetingTypes,
  updateInboxEvent,
  getMeetingTypes,
  getMeetingTypesByScope,
  addEventStaff,
  addEvent,
  deleteEventStaff,
  deleteAllEventStaffs,
  getEventScopes,
  getEventScopesDetail,
  getEventMeetingScopeByMeetingType,
  getEventBudgetInformationById,
  updateEventBudget,
  eventSignedDocsByEvent,
  sendDocumentViaEmail,
  eventSignedDocumentsPDFCreation,
  getVirtualComponents,
  getHybridComponents,
  getNumberOfAttendees,
  getIncomeTypes,
  getReconciliationOnDue,
  getPaymentTypes,
  getEventStatuses,
  getEventWorkingBudgetInformationById,
  getEventSimpleReconInformationById,
  getChangeOrderComponentTypes,
  downloadDocumentProposalPDF,
  deleteDocumentProposalPDF,
  getEventReconciliationInformationById,
  getEventReconciliationDownloadPDF,
  getEventAttachFilesById,
  deleteChangeOrderById,
  createAttachmentFile,
  inactiveAttachmentFile,
  getEventRegions,
  inactiveEvent,
  downloadReconciliationExcel,
  HTTP_ERRORS
};
export type { IEventsParams, IEventsParamsByUsers, IEventsParamsByStatus, IEventsParamsByClients };
