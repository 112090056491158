import { IClientUser } from 'types/IClients';
import { IUser } from 'types/IUser';
import { IBaseTableResponse } from 'types/IBase';
import { NullPermissions } from 'types/IRole';
import moment from 'moment';

export interface IFinancialOptionsDictionary {
  [key: string]: { name: string; budget: string; childOption: string };
}
export interface IEventStaff {
  cognitoUser: IUser;
  cognitoUserId: string;
  eventId: string;
  amiStaffSection: string;
  eventStaffRole: unknown;
  id: string;
  mondayUserId: unknown;
  role: {
    id?: number;
    name: string;
    permissions: any;
  };
}

export interface IMeetingTypeResponse {
  id: number;
  name: string;
}

export interface IMeetingScopeResponse {
  id: number;
  name: string;
  meetingTypeId: number;
  meetingType: {
    id: number;
    name: string;
    inactive: boolean;
  };
}

export interface IScopeResponse {
  id: number;
  name: string;
}

export interface IHybridComponentResponse {
  id: number;
  name: string;
}

export interface IVirtualComponentResponse {
  id: number;
  name: string;
}

export interface IMeetingTypesByScopeResponse {
  id: number;
  name: string;
  scopeId: number;
}

export enum EEventType {
  PARENT = 'Parent',
  CHILD = 'Child'
}

export interface IEvent {
  id?: string;
  jobId?: string;
  type: EEventType | null;
  childId?: number | null;
  job: {
    type: string;
    events: any[];
    deal: {
      id?: number;
      jobId?: number;
      dealdId?: number;
      dealURL: string;
    };
    createdAt: string;
  };
  createdBy: number;
  clientId?: number;
  client: IClientUser;
  mondayBoardId?: number;
  mondayEventId?: number;
  name: string;
  programObjectives: string;
  startDate: string;
  endDate: string;
  stage: string;
  dayOfWeek: string;
  duration: number;
  staffs: IEventStaff[];
  eventBudget: {
    actualProfit: number;
    actualRevenue: number;
    budgetTemplateId?: null;
    commissions: number;
    eventBudgetAccommodation: null;
    eventBudgetContractLabor: null;
    eventBudgetDisbursement: null;
    eventBudgetProduction: null;
    eventId?: number;
    id?: number;
    profit: number;
    profitMargin: number;
    programProfit: number;
    revenue: number;
    totalAttendees: number;
    totalExpense: number;
    totalProgram: number;
  };
  programScope: number;
  meetingVirtualComponent: IVirtualComponentResponse;
  meetingVirtualComponentId?: number;
  meetingHybridComponent: IHybridComponentResponse;
  meetingHybridComponentId?: number;
  scope: any;
  meetingType: IMeetingTypeResponse;
  meetingTypeId?: number;
  meetingScope: IMeetingScopeResponse;
  participantsAttendees: number;
  participantsClients: number;
  participantsStaff: number;
  platform: string;
  region: string;
  location: string;
  venue: string;
  meetingContact: IUser;
  meetingContactEmail: string;
  meetingContactPhone: string;
  meetingRequestDate: unknown;
  workloadStatus: string | number;
  eventStates: {
    eventId?: number;
    status: string;
    createdAt: string;
  }[];
  eventMeetingDimension: unknown;
  eventPostMeetingDimension: unknown;
  hubSpotDelete: boolean;
  hubSportDeleteAt: any;
  numberOfVirtualAttendees: number;
  numberOfAttendees: number;
  incomeType: string;
  paymentType: string;
  reconciliationDue: string;
}

export interface IEventsResponse extends IBaseTableResponse {
  resource: IEvent[];
}

export interface IEventSignedDocs {
  id: number;
  name: string;
  status: string;
  pandaDocId: string;
  eventId: number;
  createdAt: string;
  changeOrgerId: number;
  programBudgetId: null;
  inactive: boolean;
}

export interface IEventsSignedDocsResponse extends IBaseTableResponse {
  resource: IEventSignedDocs[];
}

export enum EEventStatuses {
  INBOX = 'Inbox',
  SIGNED = 'Signed',
  PENDING = 'Pending ',
  CANCELLED = 'Cancelled',
  TBD = 'TBD',
  RECONCILED = 'Reconciled',
  APPROVED = 'Approved'
}

export interface IRoomQuantities {
  id?: number | string;
  roomTypeId?: number | string;
  date: string;
  quantity: number | string;
}

export enum EProfitMarkupType {
  DOLLAR = 'Dollar',
  PERCENTAJE = 'Percentage'
}

export interface IRoomType {
  baseCost: number | string;
  eventBudgetAccommodationComponentId?: number;
  id?: number;
  isCommisionable: boolean;
  name: string;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  roomQuantities: IRoomQuantities[];
  unitFinalBasePrice: number;
}

export enum EComponentType {
  OVERVIEW = 'overview',
  ACCOMMODATIONS = 'accommodations',
  DATES = 'dates',
  ROOM_TYPES = 'roomTypes',
  QUANTITIES = 'quantities',
  SERVICE_FEES = 'serviceFees',
  CONTRACT_LABOR = 'contractLabor',
  DISBURSEMENTS = 'disbursements',
  PER_DIEM = 'perDiem',
  EXPENSE_REIMBURSEMENT = 'expenseReimbursement',
  HONORARIA = 'honoraria',
  EVENT_PRODUCTION = 'eventProduction',
  AUDIO_VISUAL = 'audioVisual',
  VENUE = 'venue',
  ADDITIONAL_COSTS = 'additionalCosts',
  SHIPPING_POSTAGE = 'shipping_postage',
  MARKETING = 'marketingAndAudienceGeneration',
  DIRECT_MARKETING = 'directMarketing',
  AUDIENCE_GENERATION = 'audienceGeneration',
  MARKETING_OTHER = 'marketingOther',
  SIGNAGE_AND_GRAPHICS = 'SignageAndGraphics',
  SIGNAGE = 'signage',
  SUPPORT_COLLATERAL = 'supportCollateral',
  CREATIVE_DEVELOPMENT = 'creativeDevelopment',
  MANAGEMENT_FEE = 'managementFee',
  FOOD_AND_BEVERAGE = 'foodAndBeverage',
  FOOD_AND_BEVERAGE_QUANTITIES = 'foodAndBeverageQuantities',
  FOOD_AND_BEVERAGE_TAXES_AND_FEES = 'foodAndBeverageTaxesAndFees',
  TRANSPORTATION = 'transportation',
  TRANSPORTATION_GROUND = 'transportationGround',
  TRANSPORTATION_AIR = 'transportationAir',
  AMI_INTERNAL_COST = 'amiInternalCost'
}

export interface IComponentOfSegment {
  endDate: string | null;
  eventBudgetSegmentId: number;
  id: number;
  name: string;
  roomTypes: IRoomType[];
  startDate: string | null;
  hotelPorteragePerPerson: number;
  housekeepingPerRoom: number;
  commissionRate: number;
  type: EComponentType;
  taxRate: number | string;
}

export interface IEventSegment {
  eventBudgetId: number;
  id: number;
  name: string;
  totalCost: number;
  components: IComponentOfSegment[];
}

export interface IContractLabors {
  baseCost: number | string;
  type: string;
  contractLaborTypeId: number;
  eventBudgetContractLaborComponentId: number;
  id?: number;
  numberOfPeople: number;
  numberOfUnits: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  subtotal: number;
  total: number;
  unitDescription: string;
  unitFinalBasePrice: number;
}

export interface IEventBudgetContractLabor {
  contractLabors: IContractLabors[];
  eventBudgetId?: number;
  expenses: number;
  id?: number;
  profit: number;
}

export interface IManagementFee {
  eventBudgetManagementFeeComponentId?: number;
  id?: number;
  isAmiExpenses: boolean;
  isClientExpenses: boolean;
  itemDescription: string;
  perItemFee: number;
  quantity: number;
  total: number;
}

export interface IEventBudgetManagementFee {
  amiExpenses: number;
  clientExpenses: number;
  eventBudgetId: number;
  id?: number;
  managementFee: number;
  managementFeeType: EProfitMarkupType;
  managementFees: IManagementFee[];
  profit: number;
  total: number;
  visible: boolean;
}

export interface IShippingAndPostage {
  baseCost: number | string;
  type: string;
  eventBudgetShippingAndPostageId: number;
  id?: number;
  typeName: number | string;
  numberOfPeople: number;
  numberOfUnits: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  subtotal: number;
  quantity: number;
  total: number;
  unitType: string;
  unitDescription: string;
  unitFinalBasePrice: number;
}

export interface IEventBudgetShippingAndPostage {
  shippingAndPostage: IShippingAndPostage[];
  eventBudgetId?: number;
  expenses: number;
  id?: number;
  profit: number;
}

export interface ISupportCollaterals {
  eventBudgetSignageAngGraphicsId: number;
  baseCost: number | string;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  total: number;
  subtotal: number;
  typeName: number | string;
  unitFinalBasePrice: number;
  unitType: string;
  unitDescription: string;
}

export interface ISignages {
  eventBudgetSignageAngGraphicsId: number;
  baseCost: number | string;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  total: number;
  subtotal: number;
  typeName: number | string;
  unitFinalBasePrice: number;
  unitType: string;
  numberOfUnits: number;
  unitDescription: string;
}

export interface ICreativeDevelopments {
  eventBudgetSignageAngGraphicsId: number;
  baseCost: number | string;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  total: number;
  subtotal: number;
  typeName: number | string;
  unitFinalBasePrice: number;
  unitType: string;
  unitDescription: string;
}

export interface IEventBudgetSignageAngGraphics {
  supportCollaterals: ISupportCollaterals[];
  signages: ISignages[];
  creativeDevelopments: ICreativeDevelopments[];
  eventBudgetId?: number;
  expenses: number;
  id?: number;
  profit: number;
  visible: boolean;
}

export interface IEventBudgetAccommodation {
  commission: number;
  commissionRate: number;
  endDate: string;
  eventBudgetId: number;
  expenses: number;
  hotelPorteragePerPerson: number;
  housekeepingPerRoom: number;
  id?: number;
  profit: number;
  roomTypes: IRoomType[];
  startDate: string;
  taxRate: number;
  visible?: boolean;
}

export interface IDisbursement {
  costPerDay: number;
  eventBudgetDisbursementComponentId: number;
  eventBudgetDisbursementsComponent: null;
  id: number;
  numberOfDays: number;
  profitMarkup: null;
  quantity: number;
  section: number;
  subtotal: number;
  total: number;
  type: null;
  unitFinalBasePrice: number;
}

export interface IHonorarias {
  eventBudgetDisbursementComponentId: number;
  fee: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  type: string;
  unitFinalBasePrice: number;
}

export class Honoraria implements IHonorarias {
  eventBudgetDisbursementComponentId = 0;
  fee = null;
  profitMarkup = null;
  profitMarkupType = EProfitMarkupType.PERCENTAJE;
  quantity = null;
  subtotal = 0;
  total = 0;
  type = '';
  unitFinalBasePrice = null;
}

export interface IEventBudgetDisbursement {
  eventBudgetId?: number;
  expenseAttendeesCostPerDay: number;
  expenseAttendeesNumberOfDays: number;
  expenseAttendeesProfitMarkup: number;
  expenseAttendeesProfitMarkupType: EProfitMarkupType;
  expenseAttendeesQuantity: number;
  expenseAttendeesSubtotal: number;
  expenseAttendeesTotal: number;
  expenseAttendeesUnitFinalBasePrice: number;
  expenseClientsCostPerDay: number;
  expenseClientsNumberOfDays: number;
  expenseClientsProfitMarkup: number;
  expenseClientsProfitMarkupType: EProfitMarkupType;
  expenseClientsQuantity: number;
  expenseClientsSubtotal: number;
  expenseClientsTotal: number;
  expenseClientsUnitFinalBasePrice: number;
  expenseStaffCostPerDay: number;
  expenseStaffNumberOfDays: number;
  expenseStaffProfitMarkup: number;
  expenseStaffProfitMarkupType: EProfitMarkupType;
  expenseStaffQuantity: number;
  expenseStaffSubtotal: number;
  expenseStaffTotal: number;
  expenseStaffUnitFinalBasePrice: number;
  expenses: number;
  honorarias: IHonorarias[];
  id?: number;
  managementFeePerHead: number;
  managementFeeTotal: number;
  perDiemAttendeesCostPerDay: number;
  perDiemAttendeesNumberOfDays: number;
  perDiemAttendeesProfitMarkup: number;
  perDiemAttendeesProfitMarkupType: EProfitMarkupType;
  perDiemAttendeesQuantity: number;
  perDiemAttendeesSubtotal: number;
  perDiemAttendeesTotal: number;
  perDiemAttendeesUnitFinalBasePrice: number;
  perDiemClientsCostPerDay: number;
  perDiemClientsNumberOfDays: number;
  perDiemClientsProfitMarkup: number;
  perDiemClientsProfitMarkupType: EProfitMarkupType;
  perDiemClientsQuantity: number;
  perDiemClientsSubtotal: number;
  perDiemClientsTotal: number;
  perDiemClientsUnitFinalBasePrice: number;
  perDiemStaffCostPerDay: number;
  perDiemStaffNumberOfDays: number;
  perDiemStaffProfitMarkup: number;
  perDiemStaffProfitMarkupType: EProfitMarkupType;
  perDiemStaffQuantity: number;
  perDiemStaffSubtotal: number;
  perDiemStaffTotal: number;
  perDiemStaffUnitFinalBasePrice: number;
  profit: number;
  visible: boolean;
}

export interface IProductionAdditionalCosts {
  baseCost: number | string;
  eventBudgetProductionComponentId: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
  unitType: string;
}

export interface IProductionAudioVisuals {
  baseCost: number | string;
  eventBudgetProductionComponentId: number;
  id?: number;
  isCommisionable: boolean;
  numberOfDay: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
  unitType: string;
}

export interface IProductionVenues {
  baseCost: number | string;
  eventBudgetProductionComponentId: number;
  id?: number;
  profitMarkup: number;
  numberOfDay: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
}

export interface IEventBudgetProduction {
  additionalCosts: IProductionAdditionalCosts[];
  audioVisuals: IProductionAudioVisuals[];
  commission: number;
  commissionFlatRate: number;
  commissionRate: number;
  eventBudgetId: number;
  expenses: number;
  id?: number;
  profit: number;
  venues: IProductionVenues[];
  visible: boolean;
}

export interface IAudienceGenerations {
  baseCost: number | string;
  eventBudgetMarketingAndAudienceGenerationId: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
  unitType: string;
}

export interface IDirectMarketing {
  baseCost: number | string;
  eventBudgetMarketingAndAudienceGenerationId: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
  unitType: string;
}

export interface IFoodQuantities {
  baseCost: number | string;
  eventBudgetMarketingAndAudienceGenerationId?: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
}

export interface IOtherCosts {
  baseCost: number | string;
  eventBudgetMarketingAndAudienceGenerationId: number;
  id?: number;
  profitMarkup: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  subtotal: number;
  total: number;
  typeName: string;
  unitFinalBasePrice: number;
  unitType: string;
}

export interface IEventBudgetMarketingAndAudienceGeneration {
  audienceGenerations: IAudienceGenerations[];
  directMarketing: IDirectMarketing[];
  eventBudgetId: number;
  expenses: number;
  id?: number;
  otherCosts: IOtherCosts[];
  profit: number;
  visible: boolean;
}

export interface IFoodDates {
  id?: number;
  eventBudgetFoodAndBeverageComponentId?: number;
  date: string;
  foodQuantities: IFoodQuantities[];
}
export interface IEventBudgetFoodAndBeverage {
  eventBudgetId: number;
  expenses: number;
  foodDates: IFoodDates[];
  id?: number;
  isTaxable: boolean;
  other: any;
  profit: number;
  salesTax: number;
  serviceCharge: any;
  total: number;
  visible: boolean;
}

export interface ITransportationGround {
  amiStaffBaseCost: number;
  amiStaffProfitMarkup: number;
  amiStaffIsCommissionable: boolean;
  attendeesIsCommissionable: boolean;
  clientsIsCommissionable: boolean;
  otherIsCommissionable: boolean;
  amiStaffProfitMarkupType: EProfitMarkupType;
  amiStaffQuantity: number;
  amiStaffSubtotal: number;
  amiStaffTotal: number;
  amiStaffUnitFinalBasePrice: number;
  attendeesBaseCost: number;
  attendeesProfitMarkup: number;
  attendeesProfitMarkupType: EProfitMarkupType;
  attendeesQuantity: number;
  attendeesSubtotal: number;
  attendeesTotal: number;
  attendeesUnitFinalBasePrice: number;
  clientsBaseCost: number;
  clientsProfitMarkup: number;
  clientsProfitMarkupType: EProfitMarkupType;
  clientsQuantity: number;
  clientsSubtotal: number;
  clientsTotal: number;
  clientsUnitFinalBasePrice: number;
  eventBudgetTransportationComponentId: number;
  id?: number;
  otherBaseCost: number;
  otherProfitMarkup: number;
  otherProfitMarkupType: EProfitMarkupType;
  otherQuantity: number;
  otherSubtotal: number;
  otherTotal: number;
  otherUnitFinalBasePrice: number;
  total: number;
}

export interface ITransportationAir {
  amiStaffBaseCost: number;
  amiStaffProfitMarkup: number;
  amiStaffProfitMarkupType: EProfitMarkupType;
  amiStaffQuantity: number;
  amiStaffSubtotal: number;
  amiStaffTotal: number;
  amiStaffUnitFinalBasePrice: number;
  attendeesBaseCost: number;
  attendeesProfitMarkup: number;
  attendeesProfitMarkupType: EProfitMarkupType;
  attendeesQuantity: number;
  attendeesSubtotal: number;
  attendeesTotal: number;
  attendeesUnitFinalBasePrice: number;
  clientsBaseCost: number;
  clientsProfitMarkup: number;
  clientsProfitMarkupType: EProfitMarkupType;
  clientsQuantity: number;
  clientsSubtotal: number;
  clientsTotal: number;
  clientsUnitFinalBasePrice: number;
  eventBudgetTransportationComponentId: number;
  id?: number;
  otherBaseCost: number;
  otherProfitMarkup: number;
  otherProfitMarkupType: EProfitMarkupType;
  otherQuantity: number;
  otherSubtotal: number;
  otherTotal: number;
  otherUnitFinalBasePrice: number;
}

export interface IEventBudgetTransportation {
  air: ITransportationAir;
  amiTicketManagementCostPerTicket: number;
  amiTicketManagementFeePerTicket: number;
  commission: number;
  commissionFlatRate: number;
  commissionRate: number;
  eventBudgetId: number;
  expenses: number;
  grandTotal: number;
  ground: ITransportationGround;
  id?: number;
  managementFeeTotal: number;
  perTicketTotal: number;
  profit: number;
  visible: boolean;
}

export interface IAmiInternalCosts {
  id?: number;
  itemDescription: string;
  perItemFee: number;
  quantity: number;
  total: number;
}

export interface IEventBudgetAmiInternalCost {
  amiInternalCosts: IAmiInternalCosts[];
  eventBudgetId: number;
  id?: number;
  profit: number;
  total: number;
  visible: boolean;
}

export class EventBudgetAmiInternalCostNull implements IEventBudgetAmiInternalCost {
  amiInternalCosts = [];
  eventBudgetId = 0;
  id? = 0;
  profit = 0;
  total = 0;
  visible = true;
}

export interface IEventBudgetResponse {
  actualProfit: number;
  actualRevenue: number;
  commissions: number;
  eventBudgetAccommodation: IEventBudgetAccommodation;
  eventBudgetContractLabor: IEventBudgetContractLabor;
  eventBudgetShippingAndPostage: IEventBudgetShippingAndPostage;
  eventBudgetSignageAngGraphics: IEventBudgetSignageAngGraphics;
  eventBudgetDisbursement: IEventBudgetDisbursement;
  eventBudgetProduction: IEventBudgetProduction;
  eventBudgetMarketingAndAudienceGeneration: IEventBudgetMarketingAndAudienceGeneration;
  eventBudgetFoodAndBeverage: IEventBudgetFoodAndBeverage;
  eventBudgetTransportation: IEventBudgetTransportation;
  eventBudgetManagementFee: IEventBudgetManagementFee;
  eventBudgetAmiInternalCost: IEventBudgetAmiInternalCost;
  eventId?: number;
  id?: number;
  profit: number;
  profitMargin: number;
  programProfit: number;
  revenue: number;
  totalAttendees: number;
  totalExpense: number;
  totalProgram: number;
  totalProgramProfit: number;
  totalManagementFee: number;
}

export interface IEventWorkingBudgetBaseModel {
  amiCostTotal: number;
  baseCost: number;
  budgetedClientCostEach: number;
  clientCostEach: number;
  clientCostTotal: number;
  clientSubtotal: number;
  clientSvcCharge: number;
  clientTax: number;
  componentType: EComponentType;
  date: string;
  descriptor: string;
  eventWorkingBudgetId?: number;
  id?: number;
  markup: number;
  profitGoal?: number;
  profitMarkupType: EProfitMarkupType;
  quantity: number;
  totalProfit?: number;
  units: number;
  variance?: number;
}

export interface IEventReconciliationComponentBaseModel {
  actualExpense?: number;
  actualFromQB?: number;
  amiProfit?: number;
  comments?: string;
  costEach?: number;
  date?: string;
  descriptor?: string;
  eventReconciliationBudgetId?: number;
  id?: number;
  itemBudget?: number;
  quantity?: number;
  reconciliationComponentType?: string;
  variance?: number;
}

export interface IAttachmentBudgetFiles {
  id?: number;
  attachmentBudgetCommentId?: string;
  attachmentBudgetComment?: string;
  fileName: string;
  objectKey: string;
}

export interface IAttachFile {
  date: string;
  cognitoUser: IUser;
  eventId: number;
  fileName: string;
  id: number;
  inactive: boolean;
  notes: string;
  attachmentBudgetFiles: IAttachmentBudgetFiles[];
}

export interface IAttachmentLoadReponse {
  contentLength?: number;
  httpStatusCode?: number;
  responseMetadata?: any;
  url: string;
}

export interface IEventReconciliation {
  event: any;
  eventId?: number;
  eventReconciliationBudgetAccommodationLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetAirlineTravelLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetContractLaborLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetCreativeDevelopmentLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetId?: number;
  date: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetDisbursementLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetEventProductionAdditionalCostsLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetEventProductionAudioVisualLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetEventProductionVenueLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetFoodAndBeverageLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetGroundTransportationLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetManagementFeeLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetMarketingAndAudienceGenerationLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetShippingAndPostageLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetSignageAndGraphicsLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationBudgetSupportCollateralLines: IEventReconciliationComponentBaseModel[];
  eventReconciliationChangeOrderLines: IEventReconciliationComponentBaseModel[];
  id?: number;
  totalChangeOrdersLine: IEventReconciliationComponentBaseModel;
  totalEventExpensesLine: IEventReconciliationComponentBaseModel;
  totalProgramBudgetLine: IEventReconciliationComponentBaseModel;
}

export interface IEventChangeOrders {
  accommodationRoomTax: number;
  amiCostTotal: number;
  changeOrderNumber?: number;
  clientCostTotal: number;
  clientSubtotal: number;
  clientSvcCharge: number;
  clientTax: number;
  cognitoUserId?: number | string;
  createdDate?: string;
  descriptor: string;
  eventChangeOrderLines: IEventWorkingBudgetBaseModel[];
  eventSignedDocumentId?: number;
  eventWorkingBudgetId?: number;
  foodAndBeverageServiceCharge: number;
  foodAndBeverageTax: number;
  id?: number;
  inactive: boolean;
  profitGoal: number;
  totalProfit: number;
  variance: number;
  status: string;
}
export class WorkingBudgetComponentNull implements IEventWorkingBudgetBaseModel {
  amiCostTotal = 0;
  baseCost = null;
  budgetedClientCostEach = 0;
  clientCostEach = 0;
  clientCostTotal = 0;
  clientSubtotal = 0;
  clientSvcCharge = 0;
  clientTax = 0;
  componentType: null;
  date = null;
  descriptor = '';
  eventWorkingBudgetId? = 0;
  markup = 0;
  profitMarkupType = EProfitMarkupType.DOLLAR;
  quantity = 0;
  units = 0;
}

export class EventChangeOrdersNull implements IEventChangeOrders {
  accommodationRoomTax = 0;
  descriptor = null;
  eventChangeOrderLines = [];
  foodAndBeverageServiceCharge = 0;
  foodAndBeverageTax = 0;
  profitGoal = 0;
  totalProfit = 0;
  variance = 0;
  cognitoUserId: number | string;
  createdDate = moment().format();
  constructor(cognitoUserId: number | string) {
    this.cognitoUserId = cognitoUserId;
  }
  clientSubtotal = 0;
  clientSvcCharge = 0;
  clientTax = 0;
  inactive = false;
  status = 'new';
  amiCostTotal = 0;
  clientCostTotal = 0;
}

export interface IEventWorkingCommissionsAndTaxes {
  accommodationRoomTaxActual: number;
  accommodationRoomTaxBudgeted: number;
  disbursementHonorariaMangementFeeActual: number;
  disbursementHonorariaMangementFeeBudgeted: number;
  eventWorkingBudgetId?: number;
  foodAndBeverageServiceChargeActual: number;
  foodAndBeverageServiceChargeBudgeted: number;
  foodAndBeverageTaxActual: number;
  foodAndBeverageTaxBudgeted: number;
  id?: number;
  productionActualProfitMarkupType: EProfitMarkupType;
  productionBudgetedProfitMarkupType: EProfitMarkupType;
  productionCommissionActual: number;
  productionCommissionBudgeted: number;
  transportationAirActualProfitMarkupType: EProfitMarkupType;
  transportationAirBudgetedProfitMarkupType: EProfitMarkupType;
  transportationAirCommissionActual: number;
  transportationAirCommissionBudgeted: number;
  transportationGroundActualProfitMarkupType: EProfitMarkupType;
  transportationGroundBudgetedProfitMarkupType: EProfitMarkupType;
  transportationGroundCommissionActual: number;
  transportationGroundCommissionBudgeted: number;
}

export interface ITaxLineTypes {
  value: any;
  attribute: string;
  label: string;
  descriptor?: string;
}
export interface ITaxLine {
  types: ITaxLineTypes[];
}

export interface IEventWorkingBudgetInternalCostLinesModel {
  actual: number;
  amiCostTotal: number;
  budgeted: number;
  descriptor: string;
  eventWorkingBudgetId?: number;
  id?: number;
  quantity: number;
}

export interface IEventWorkingBudget {
  commissions: number;
  event: IEvent;
  eventChangeOrders: IEventChangeOrders[];
  eventId?: number;
  eventWorkingBudgetAccommodationLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetContractLaborLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetDisbursementLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetFoodAndBeverageLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetInternalCostLines: IEventWorkingBudgetInternalCostLinesModel[];
  eventWorkingBudgetManagementFeeLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetMarketingAndAudienceGenerationLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetProductionLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetShippingAndPostageLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetSignageAndGraphicsLines: IEventWorkingBudgetBaseModel[];
  eventWorkingBudgetTransportationLines: IEventWorkingBudgetBaseModel[];
  eventWorkingCommissionsAndTaxes: IEventWorkingCommissionsAndTaxes;
  id?: number;
  profitMargin: number;
  programProfit: number;
  totalAttendees: number;
  totalExpense: number;
  totalManagementFee: number;
  totalProfit: number;
  totalProfitGoal: number;
  totalProgram: number;
  totalProgramProfit: number;
  totalVariance: number;
}
export interface IEventSimpleRecon {
  event?: IEvent;
  eventId?: number;
  id?: number;
  programProfit: number;
  totalProgram: number;
}

export class EventBudgetProductionNull implements IEventBudgetProduction {
  additionalCosts = [];
  audioVisuals = [];
  commission = 0;
  commissionFlatRate = 0;
  commissionRate = 0;
  eventBudgetId = 0;
  expenses = 0;
  profit = 0;
  venues = [];
  visible = true;
}

export class EventBudgetAccommodationNull implements IEventBudgetAccommodation {
  commission = 0;
  commissionRate = 0;
  endDate = '';
  eventBudgetId = 0;
  expenses = 0;
  hotelPorteragePerPerson = 0;
  housekeepingPerRoom = 0;
  profit = 0;
  roomTypes = [];
  startDate = '';
  taxRate = 0;
}

export class EventBudgetContractLaborNull implements IEventBudgetContractLabor {
  contractLabors = [];
  expenses: 0;
  profit: 0;
}

export class EventEventShippingAndPostageNull implements IEventBudgetShippingAndPostage {
  shippingAndPostage = [];
  expenses: 0;
  profit: 0;
}

export class EventBudgetMarketingAndAudienceGenerationNull implements IEventBudgetMarketingAndAudienceGeneration {
  audienceGenerations = [];
  directMarketing = [];
  eventBudgetId = 0;
  expenses = 0;
  otherCosts = [];
  profit = 0;
  visible = true;
}

export class EventBudgetSignageAngGraphicsNull implements IEventBudgetSignageAngGraphics {
  supportCollaterals = [];
  signages = [];
  creativeDevelopments = [];
  eventBudgetId = 0;
  expenses = 0;
  profit = 0;
  visible = true;
}

export class EventBudgetFoodAndBeverageNull implements IEventBudgetFoodAndBeverage {
  eventBudgetId = 0;
  expenses = 0;
  foodDates = [];
  isTaxable = false;
  other = NullPermissions;
  profit = 0;
  salesTax = 0;
  serviceCharge = null;
  total = 0;
  visible = true;
}

export class EventBudgetTransportationGroundNull implements ITransportationGround {
  amiStaffBaseCost = null;
  amiStaffProfitMarkup = 0;
  amiStaffIsCommissionable = false;
  attendeesIsCommissionable = false;
  clientsIsCommissionable = false;
  otherIsCommissionable = false;
  amiStaffProfitMarkupType = EProfitMarkupType.DOLLAR;
  amiStaffQuantity = 0;
  amiStaffSubtotal = 0;
  amiStaffTotal = 0;
  amiStaffUnitFinalBasePrice = 0;
  attendeesBaseCost = null;
  attendeesProfitMarkup = 0;
  attendeesProfitMarkupType = EProfitMarkupType.DOLLAR;
  attendeesQuantity = 0;
  attendeesSubtotal = 0;
  attendeesTotal = 0;
  attendeesUnitFinalBasePrice = 0;
  clientsBaseCost = null;
  clientsProfitMarkup = 0;
  clientsProfitMarkupType = EProfitMarkupType.DOLLAR;
  clientsQuantity = 0;
  clientsSubtotal = 0;
  clientsTotal = 0;
  clientsUnitFinalBasePrice = 0;
  eventBudgetTransportationComponentId = 0;
  otherBaseCost = null;
  otherProfitMarkup = 0;
  otherProfitMarkupType = EProfitMarkupType.DOLLAR;
  otherQuantity = 0;
  otherSubtotal = 0;
  otherTotal = 0;
  otherUnitFinalBasePrice = 0;
  total = 0;
}

export class EventBudgetTransportationAirNull implements ITransportationAir {
  amiStaffBaseCost = null;
  amiStaffProfitMarkup = 0;
  amiStaffProfitMarkupType = EProfitMarkupType.DOLLAR;
  amiStaffQuantity = 0;
  amiStaffSubtotal = 0;
  amiStaffTotal = 0;
  amiStaffUnitFinalBasePrice = 0;
  attendeesBaseCost = null;
  attendeesProfitMarkup = 0;
  attendeesProfitMarkupType = EProfitMarkupType.DOLLAR;
  attendeesQuantity = 0;
  attendeesSubtotal = 0;
  attendeesTotal = 0;
  attendeesUnitFinalBasePrice = 0;
  clientsBaseCost = null;
  clientsProfitMarkup = 0;
  clientsProfitMarkupType = EProfitMarkupType.DOLLAR;
  clientsQuantity = 0;
  clientsSubtotal = 0;
  clientsTotal = 0;
  clientsUnitFinalBasePrice = 0;
  eventBudgetTransportationComponentId = 0;
  otherBaseCost = null;
  otherProfitMarkup = 0;
  otherProfitMarkupType = EProfitMarkupType.DOLLAR;
  otherQuantity = 0;
  otherSubtotal = 0;
  otherTotal = 0;
  otherUnitFinalBasePrice = 0;
}

export class EventBudgetTransportationNull implements IEventBudgetTransportation {
  air = new EventBudgetTransportationAirNull();
  amiTicketManagementCostPerTicket = 0;
  amiTicketManagementFeePerTicket = 0;
  commission = 0;
  commissionFlatRate = 0;
  commissionRate = 0;
  eventBudgetId = 0;
  expenses = 0;
  grandTotal = 0;
  ground = new EventBudgetTransportationGroundNull();
  managementFeeTotal = 0;
  perTicketTotal = 0;
  profit = 0;
  visible = true;
}

export class EventBudgetManagementFeeNull implements IEventBudgetManagementFee {
  amiExpenses = 0;
  clientExpenses = 0;
  eventBudgetId = 0;
  managementFee = 0;
  managementFeeType = EProfitMarkupType.PERCENTAJE;
  managementFees = [];
  profit = 0;
  total = 0;
  visible = true;
}

export class EventBudgetDisbursementsNull implements IEventBudgetDisbursement {
  expenseAttendeesCostPerDay = 0;
  expenseAttendeesNumberOfDays = 0;
  expenseAttendeesProfitMarkup = 0;
  expenseAttendeesProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  expenseAttendeesQuantity = 0;
  expenseAttendeesSubtotal = 0;
  expenseAttendeesTotal = 0;
  expenseAttendeesUnitFinalBasePrice = 0;
  expenseClientsCostPerDay = 0;
  expenseClientsNumberOfDays = 0;
  expenseClientsProfitMarkup = 0;
  expenseClientsProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  expenseClientsQuantity = 0;
  expenseClientsSubtotal = 0;
  expenseClientsTotal = 0;
  expenseClientsUnitFinalBasePrice = 0;
  expenseStaffCostPerDay = 0;
  expenseStaffNumberOfDays = 0;
  expenseStaffProfitMarkup = 0;
  expenseStaffProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  expenseStaffQuantity = 0;
  expenseStaffSubtotal = 0;
  expenseStaffTotal = 0;
  expenseStaffUnitFinalBasePrice = 0;
  expenses = 0;
  honorarias = [];
  managementFeePerHead = 0;
  managementFeeTotal = 0;
  perDiemAttendeesCostPerDay = 0;
  perDiemAttendeesNumberOfDays = 0;
  perDiemAttendeesProfitMarkup = 0;
  perDiemAttendeesProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  perDiemAttendeesQuantity = 0;
  perDiemAttendeesSubtotal = 0;
  perDiemAttendeesTotal = 0;
  perDiemAttendeesUnitFinalBasePrice = 0;
  perDiemClientsCostPerDay = 0;
  perDiemClientsNumberOfDays = 0;
  perDiemClientsProfitMarkup = 0;
  perDiemClientsProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  perDiemClientsQuantity = 0;
  perDiemClientsSubtotal = 0;
  perDiemClientsTotal = 0;
  perDiemClientsUnitFinalBasePrice = 0;
  perDiemStaffCostPerDay = 0;
  perDiemStaffNumberOfDays = 0;
  perDiemStaffProfitMarkup = 0;
  perDiemStaffProfitMarkupType = EProfitMarkupType.PERCENTAJE;
  perDiemStaffQuantity = 0;
  perDiemStaffSubtotal = 0;
  perDiemStaffTotal = 0;
  perDiemStaffUnitFinalBasePrice = 0;
  profit = 0;
  visible = false;
}

export class EventBudgetResponseNull implements IEventBudgetResponse {
  actualProfit = 0;
  actualRevenue = 0;
  commissions = 0;
  eventBudgetAccommodation = new EventBudgetAccommodationNull();
  eventBudgetContractLabor = new EventBudgetContractLaborNull();
  eventBudgetDisbursement = new EventBudgetDisbursementsNull();
  eventBudgetProduction = new EventBudgetProductionNull();
  eventBudgetShippingAndPostage = new EventEventShippingAndPostageNull();
  eventBudgetMarketingAndAudienceGeneration = new EventBudgetMarketingAndAudienceGenerationNull();
  eventBudgetSignageAngGraphics = new EventBudgetSignageAngGraphicsNull();
  eventBudgetManagementFee = new EventBudgetManagementFeeNull();
  eventBudgetFoodAndBeverage = new EventBudgetFoodAndBeverageNull();
  eventBudgetTransportation = new EventBudgetTransportationNull();
  eventBudgetAmiInternalCost = new EventBudgetAmiInternalCostNull();
  profit = 0;
  profitMargin = 0;
  programProfit = 0;
  revenue = 0;
  totalAttendees = 0;
  totalExpense = 0;
  totalProgram = 0;
  totalProgramProfit = 0;
  totalManagementFee = 0;
}

export class EventNull implements IEvent {
  type = null;
  job = {
    type: '',
    events: [],
    deal: {
      dealURL: ''
    },
    createdAt: ''
  };
  createdBy = 0;
  client = null;
  name = '';
  programObjectives = '';
  startDate = '';
  endDate = '';
  stage = '';
  dayOfWeek = '';
  duration = 0;
  staffs = [];
  eventBudget = {
    actualProfit: 0,
    actualRevenue: 0,
    commissions: 0,
    eventBudgetAccommodation: null,
    eventBudgetContractLabor: null,
    eventBudgetDisbursement: null,
    eventBudgetProduction: null,
    profit: 0,
    profitMargin: 0,
    programProfit: 0,
    revenue: 0,
    totalAttendees: 0,
    totalExpense: 0,
    totalProgram: 0
  };
  programScope = 0;
  meetingVirtualComponent = null;
  meetingHybridComponent = null;
  scope = null;
  meetingType = null;
  meetingScope = null;
  participantsAttendees = 0;
  participantsClients = 0;
  participantsStaff = 0;
  platform = '';
  region = '';
  location = '';
  venue = '';
  meetingContact = null;
  meetingContactEmail = '';
  meetingContactPhone = '';
  meetingRequestDate = null;
  workloadStatus = null;
  eventStates = null;
  eventMeetingDimension = null;
  eventPostMeetingDimension = null;
  hubSpotDelete = false;
  hubSportDeleteAt = null;
  numberOfVirtualAttendees = 0;
  numberOfAttendees = 0;
  incomeType = '';
  paymentType = '';
  reconciliationDue = '';
}

export class EventSimpleReconNull implements IEventSimpleRecon {
  event?: IEvent;
  programProfit = 0;
  totalProgram = 0;
  eventId?: number;
  id?: number;
}

export enum EEventBudgetComponent {
  eventBudgetAccommodation = 'eventBudgetAccommodation',
  eventBudgetContractLabor = 'eventBudgetContractLabor',
  eventBudgetDisbursement = 'eventBudgetDisbursement',
  eventBudgetFoodAndBeverage = 'eventBudgetFoodAndBeverage',
  eventBudgetMarketingAndAudienceGeneration = 'eventBudgetMarketingAndAudienceGeneration',
  eventBudgetProduction = 'eventBudgetProduction',
  eventBudgetShippingAndPostage = 'eventBudgetShippingAndPostage',
  eventBudgetSignageAngGraphics = 'eventBudgetSignageAngGraphics',
  eventBudgetTransportation = 'eventBudgetTransportation',
  eventBudgetManagementFee = 'eventBudgetManagementFee',
  eventBudgetAmiInternalCost = 'eventBudgetAmiInternalCost'
}

export enum EEventWorkingBudgetComponent {
  eventWorkingBudgetAccommodationLines = 'eventWorkingBudgetAccommodationLines',
  eventWorkingBudgetContractLaborLines = 'eventWorkingBudgetContractLaborLines',
  eventWorkingBudgetDisbursementLines = 'eventWorkingBudgetDisbursementLines',
  eventWorkingBudgetFoodAndBeverageLines = 'eventWorkingBudgetFoodAndBeverageLines',
  eventWorkingBudgetInternalCostLines = 'eventWorkingBudgetInternalCostLines',
  eventWorkingBudgetManagementFeeLines = 'eventWorkingBudgetManagementFeeLines',
  eventWorkingBudgetMarketingAndAudienceGenerationLines = 'eventWorkingBudgetMarketingAndAudienceGenerationLines',
  eventWorkingBudgetProductionLines = 'eventWorkingBudgetProductionLines',
  eventWorkingBudgetShippingAndPostageLines = 'eventWorkingBudgetShippingAndPostageLines',
  eventWorkingBudgetSignageAndGraphicsLines = 'eventWorkingBudgetSignageAndGraphicsLines',
  eventWorkingBudgetTransportationLines = 'eventWorkingBudgetTransportationLines',
  eventWorkingCommissionsAndTaxes = 'eventWorkingCommissionsAndTaxes',
  eventChangeOrders = 'eventChangeOrders'
}

export const EventWorkingBudgetComponents = [
  {
    name: 'eventWorkingBudgetAccommodationLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetAccommodationLines
  },
  {
    name: 'eventWorkingBudgetContractLaborLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetContractLaborLines
  },
  {
    name: 'eventWorkingBudgetDisbursementLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetDisbursementLines
  },
  {
    name: 'eventWorkingBudgetFoodAndBeverageLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetFoodAndBeverageLines
  },
  {
    name: 'eventWorkingBudgetInternalCostLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetInternalCostLines
  },
  {
    name: 'eventWorkingBudgetManagementFeeLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetManagementFeeLines
  },
  {
    name: 'eventWorkingBudgetMarketingAndAudienceGenerationLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetMarketingAndAudienceGenerationLines
  },
  {
    name: 'eventWorkingBudgetProductionLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetProductionLines
  },
  {
    name: 'eventWorkingBudgetShippingAndPostageLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetShippingAndPostageLines
  },
  {
    name: 'eventWorkingBudgetSignageAndGraphicsLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetSignageAndGraphicsLines
  },
  {
    name: 'eventWorkingBudgetTransportationLines',
    type: EEventWorkingBudgetComponent.eventWorkingBudgetTransportationLines
  },
  {
    name: 'eventChangeOrders',
    type: EEventWorkingBudgetComponent.eventChangeOrders
  }
];

export enum EEventReconciliationComponent {
  eventReconciliationBudgetAccommodationLines = 'eventReconciliationBudgetAccommodationLines',
  eventReconciliationBudgetAirlineTravelLines = 'eventReconciliationBudgetAirlineTravelLines',
  eventReconciliationBudgetContractLaborLines = 'eventReconciliationBudgetContractLaborLines',
  eventReconciliationBudgetCreativeDevelopmentLines = 'eventReconciliationBudgetCreativeDevelopmentLines',
  eventReconciliationBudgetDisbursementLines = 'eventReconciliationBudgetDisbursementLines',
  eventReconciliationBudgetEventProductionAdditionalCostsLines = 'eventReconciliationBudgetEventProductionAdditionalCostsLines',
  eventReconciliationBudgetEventProductionAudioVisualLines = 'eventReconciliationBudgetEventProductionAudioVisualLines',
  eventReconciliationBudgetEventProductionVenueLines = 'eventReconciliationBudgetEventProductionVenueLines',
  eventReconciliationBudgetFoodAndBeverageLines = 'eventReconciliationBudgetFoodAndBeverageLines',
  eventReconciliationBudgetGroundTransportationLines = 'eventReconciliationBudgetGroundTransportationLines',
  eventReconciliationBudgetManagementFeeLines = 'eventReconciliationBudgetManagementFeeLines',
  eventReconciliationBudgetMarketingAndAudienceGenerationLines = 'eventReconciliationBudgetMarketingAndAudienceGenerationLines',
  eventReconciliationBudgetShippingAndPostageLines = 'eventReconciliationBudgetShippingAndPostageLines',
  eventReconciliationBudgetSignageAndGraphicsLines = 'eventReconciliationBudgetSignageAndGraphicsLines',
  eventReconciliationBudgetSupportCollateralLines = 'eventReconciliationBudgetSupportCollateralLines',
  eventReconciliationChangeOrderLines = 'eventReconciliationChangeOrderLines',
  totalProgramBudgetLine = 'totalProgramBudgetLine',
  totalEventExpensesLine = 'totalEventExpensesLine',
  totalChangeOrdersLine = 'totalChangeOrdersLine'
}

export const EventReconciliationComponents = [
  {
    name: 'eventReconciliationBudgetAccommodationLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetAccommodationLines
  },
  {
    name: 'eventReconciliationBudgetAirlineTravelLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetAirlineTravelLines
  },
  {
    name: 'eventReconciliationBudgetContractLaborLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetContractLaborLines
  },
  {
    name: 'eventReconciliationBudgetCreativeDevelopmentLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetCreativeDevelopmentLines
  },
  {
    name: 'eventReconciliationBudgetCreativeDevelopmentLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetCreativeDevelopmentLines
  },
  {
    name: 'eventReconciliationBudgetDisbursementLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetDisbursementLines
  },
  {
    name: 'eventReconciliationBudgetEventProductionAdditionalCostsLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetEventProductionAdditionalCostsLines
  },
  {
    name: 'eventReconciliationBudgetEventProductionAudioVisualLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetEventProductionAudioVisualLines
  },
  {
    name: 'eventReconciliationBudgetEventProductionVenueLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetEventProductionVenueLines
  },
  {
    name: 'eventReconciliationBudgetFoodAndBeverageLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetFoodAndBeverageLines
  },
  {
    name: 'eventReconciliationBudgetGroundTransportationLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetGroundTransportationLines
  },
  {
    name: 'eventReconciliationBudgetManagementFeeLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetManagementFeeLines
  },
  {
    name: 'eventReconciliationBudgetMarketingAndAudienceGenerationLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetMarketingAndAudienceGenerationLines
  },
  {
    name: 'eventReconciliationBudgetShippingAndPostageLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetShippingAndPostageLines
  },
  {
    name: 'eventReconciliationBudgetSignageAndGraphicsLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetSignageAndGraphicsLines
  },
  {
    name: 'eventReconciliationBudgetSupportCollateralLines',
    type: EEventReconciliationComponent.eventReconciliationBudgetSupportCollateralLines
  },
  {
    name: 'eventReconciliationChangeOrderLines',
    type: EEventReconciliationComponent.eventReconciliationChangeOrderLines
  },
  {
    name: 'totalChangeOrdersLine',
    type: EEventReconciliationComponent.totalChangeOrdersLine
  },
  {
    name: 'totalEventExpensesLine',
    type: EEventReconciliationComponent.totalEventExpensesLine
  },
  {
    name: 'totalProgramBudgetLine',
    type: EEventReconciliationComponent.totalProgramBudgetLine
  }
];
