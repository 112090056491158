/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getRefreshToken, getToken, setRefreshToken, setToken } from 'utils/authentication';
import { getRefreshToken as getFetchRefreshToken } from 'services/http';
import { IFetchOptions, EMethods } from './types';

export const fetchAPI = async (options: IFetchOptions): Promise<any | Error> =>
  new Promise(async (resolve, reject) => {
    if (!options?.URL) return reject(new Error('No URL found'));
    if (options.method === EMethods.GET) {
      try {
        if (options?.paramInUrlLine) {
          const response = await axios(`${options.URL}/${options.params}`, {
            method: options.method
          });
          return resolve(response.data);
        }
        const response = await axios(options.URL, {
          params: options.params,
          method: options.method
        });
        return resolve(response.data);
      } catch (error) {
        return reject(new Error(error));
      }
    }
    if (options.method === EMethods.PUT) {
      try {
        if (options?.paramInUrlLine && options.params) {
          const response = await axios(`${options.URL}/${options.params}`, {
            method: options.method
          });
          return resolve(response.data);
        }
        if (options.params) {
          const response = await axios(`${options.URL}?${options.params}`, {
            method: options.method
          });
          return resolve(response.data);
        }
        const response = await axios(options.URL, {
          method: options.method,
          data: options.body
        });
        return resolve(response.data);
      } catch (error) {
        return reject(new Error(error));
      }
    }

    if (options.method === EMethods.POST) {
      try {
        if (options?.paramInUrlLine && options.params) {
          const response = await axios(`${options.URL}${options.params}`, {
            method: options.method
          });
          return resolve(response.data);
        }
        if (options?.params) {
          const response = await axios(options.URL, {
            method: options.method,
            params: options.params
          });
          return resolve(response.data);
        }

        let requestObj;
        if (options.headers) {
          requestObj = {
            method: EMethods.POST,
            data: options.body,
            headers: options.headers
          };
        } else {
          requestObj = {
            method: EMethods.POST,
            data: options.body
          };
        }

        const response = await axios(options.URL, requestObj);

        return resolve(response.data);
      } catch (error) {
        const ErrorMessages = error?.response?.data?.messages;
        if (ErrorMessages) {
          return reject(new Error(ErrorMessages[0]));
        }
        return reject(new Error(error));
      }
    }

    if (options.method === EMethods.DELETE) {
      if (!options?.params) return reject(new Error('No params found'));
      try {
        if (options?.paramInUrlLine && options.params) {
          const response = await axios(`${options.URL}${options.params}`, {
            method: options.method
          });
          return resolve(response.data);
        }
        const response = await axios(`${options.URL}/${options.params}`, {
          method: options.method
        });
        return resolve(response.data);
      } catch (error) {
        return reject(new Error(error));
      }
    }
    return reject(new Error('Method not supported'));
  });

axios.interceptors.request.use(
  (config) => {
    config.headers.authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();
    if (refreshToken && error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return getFetchRefreshToken().then(({ refresh_token, access_token }) => {
        setRefreshToken(refresh_token);
        setToken(access_token);
        return axios(originalRequest);
      });
    }
    return Promise.reject(error);
  }
);
