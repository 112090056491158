import { EEventStatuses } from 'types/IEvent';
import { ROUTE_PATH } from 'config/constants';

/* eslint-disable @typescript-eslint/indent */
export const getChildOption = (optionName: string, financialOptions: Array<any>) => {
  let resultOption = null;
  for (let i = 0; i < financialOptions.length; i++) {
    if (financialOptions[i].children && financialOptions[i].children.length > 0) {
      for (let j = 0; j < financialOptions[i].children.length; j++) {
        if (financialOptions[i].children[j].type && financialOptions[i].children[j].type === optionName) {
          resultOption = financialOptions[i].children[j];
          break;
        }
      }
    }
  }
  return resultOption;
};

export const getOptionByName = (optionName: string, financialOptions: Array<any>) => {
  let resultOption = null;
  for (let i = 0; i < financialOptions.length; i++) {
    if (financialOptions[i].type && financialOptions[i].type === optionName) {
      resultOption = financialOptions[i];
      break;
    }
  }
  return resultOption;
};

export const replaceForClassNames = (str) => {
  str = str.replace(/\s+/g, '-').toLowerCase();
  str = str.replace('&', '-');
  return str;
};

export const isApprovedEvent = (event) =>
  event?.eventStates?.length && event.eventStates[0].status === EEventStatuses.APPROVED;

export const isSignedEvent = (event) =>
  event?.eventStates?.length &&
  (event.eventStates[0].status === EEventStatuses.SIGNED ||
    event.eventStates[0].status === EEventStatuses.RECONCILED ||
    isApprovedEvent(event));

export const navigationAction = ({
  optionToNavigate,
  financialOptions,
  updateCurrentFinancialOption,
  navigate,
  eventId
}) => {
  if (optionToNavigate) {
    const optionChild =
      getChildOption(optionToNavigate, financialOptions) || getOptionByName(optionToNavigate, financialOptions);
    updateCurrentFinancialOption(optionChild);
  } else {
    if (eventId) {
      navigate(`${ROUTE_PATH.DASHBOARD.EVENT}/${eventId}`);
    }
    if (!eventId) {
      navigate(`${ROUTE_PATH.DASHBOARD.BUDGET_TEMPLATE.ROOT}`);
    }
  }
};
