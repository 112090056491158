import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import CurrencyDollar from 'icons/CurrencyDollar';
import { EProfitMarkupType } from 'types/IEvent';

const ProfitMarkup = ({
  handleToogleProfitMarkup,
  handleOnChange,
  value,
  profitMarkupType,
  label,
  fullWidth,
  disabled,
  sx
}: {
  handleToogleProfitMarkup: () => void;
  handleOnChange: (_: any) => void;
  value: string | number;
  profitMarkupType: EProfitMarkupType;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  sx?: any;
}) => (
  <TextField
    fullWidth={fullWidth ? true : fullWidth}
    size="small"
    variant="outlined"
    name="profitMarkup"
    value={value}
    onChange={handleOnChange}
    label={label || null}
    disabled={disabled}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconButton
            edge="start"
            aria-label="toggle profit markup type"
            size="small"
            onClick={handleToogleProfitMarkup}
          >
            {profitMarkupType === EProfitMarkupType.DOLLAR ? <CurrencyDollar /> : <> % </>}
          </IconButton>
        </InputAdornment>
      )
    }}
    sx={sx}
  />
);

export default ProfitMarkup;
