import { IBaseTableResponse } from 'types/IBase';
import { IEventBudgetResponse } from 'types/IEvent';

export interface IComponentBudgetTemplateSegments {
  name: string;
  type: number | string;
}
export interface IBudgetTemplateSegments {
  components: IComponentBudgetTemplateSegments[];
  id?: number;
  name: string;
  budgetTemplateId?: number;
}

export interface IBudgetTemplate {
  id?: number;
  name?: string;
  inactive?: boolean;
  createdBy?: number | string;
  eventBudget: IEventBudgetResponse;
}

export interface IBudgetTemplateResponse extends IBaseTableResponse {
  resource: IBudgetTemplate[];
}

export enum EBudgetTemplateType {
  CREATE = 'create',
  EDIT = 'edit'
}

export interface GenericComponenteType {
  id?: number;
  typeName: string;
}
