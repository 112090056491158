import { createContext } from 'react';
import { IUser } from 'types/IUser';

interface RoleGuardContextProps {
  permissions: {
    create: (user: IUser, moduleId?: number) => boolean;
    read: (user: IUser, moduleId?: number) => boolean;
    update: (user: IUser, moduleId?: number) => boolean;
    delete: (user: IUser, moduleId?: number) => boolean;
  };
  currentModule: number;
}

const RoleGuardContext = createContext<RoleGuardContextProps>({ permissions: null, currentModule: null });

export default RoleGuardContext;
