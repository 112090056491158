export interface IClient {
  message: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  resource: IClientUser[];
  success: boolean;
}

export interface IClientsUser {
  id?: string;
  client?: IClientUser;
  clientId?: string;
}
export interface IClientUser {
  id: string;
  name: string;
  inactive?: boolean;
  template?: string;
}

export interface IClientUserUpdate {
  clientId: string | number;
}

export class NullClients implements IClientsUser {
  public id = '';
  public clientId = '';
  public client = {
    id: '',
    name: ''
  };
}

export interface IClientsResponse {
  pageNumber: number;
  pageSize: number;
  totalPage: number;
  totalRecords: number;
  success: boolean;
  message: string;
  resource: IClientUser[];
}
