/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from 'config';
import {
  signUp,
  resetPassword,
  getUserInformationByEmail,
  updateUserInformation,
  forgotPassword,
  getAccessToken
} from 'services/http';
import type { IUser } from 'types/IUser';
import { IClientsUser, NullClients } from 'types/IClients';
import { setRefreshToken, setToken } from 'utils/authentication';

Amplify.configure(amplifyConfig);

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: IUser | null;
}

export interface IRegister {
  email: string;
  name: string;
  familyName: string;
  phoneNumber: string;
  picture: string;
  role: string;
  jobPosition: string;
  companyId: string;
  companyName: string;
  permissions: string;
  clients?: IClientsUser[];
}

interface AuthContextValue extends State {
  platform: 'Amplify';
  login: (email: string, password: string) => Promise<void | Error>;
  logout: () => Promise<void>;
  register: (data: IRegister) => Promise<void>;
  updateUser: (user: IUser, propagateChanges: boolean) => Promise<void>;
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  updatePicture: (url: string, user: IUser, propagateChanges: boolean) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (username: string, newPassword: string) => Promise<void>;
  passwordResetVerify: (username: string, code: string, newPassword: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: IUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: IUser;
  };
};

type UpdatePasswordAction = {
  type: 'UPDATE_PASSWORD';
};

type UpdatePictureAction = {
  type: 'UPDATE_PICTURE';
  payload: {
    user: IUser;
  };
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateUserAction
  | UpdatePasswordAction
  | UpdatePictureAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State): State => ({ ...state }),
  UPDATE_USER: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  UPDATE_PASSWORD: (state: State): State => ({ ...state }),
  UPDATE_PICTURE: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user
    };
  },
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state })
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  updatePicture: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  passwordResetVerify: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token, refresh_token } = await getAccessToken();
        setToken(access_token);
        setRefreshToken(refresh_token);
        const user = await getUserInformationByEmail({
          email: cognitoUser.attributes.email
        });

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.id,
              userName: user.userName,
              picture: user.picture,
              email: user.email,
              name: user.name,
              familyName: user.familyName,
              phoneNumber: user.phoneNumber,
              jobPosition: user.jobPosition,
              role: user.role,
              roleId: user.roleId,
              clients: user?.clients || [new NullClients()]
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void | Error> => {
    const cognitoUser = await Auth.signIn(email, password);

    if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      if (cognitoUser.Session) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token } = await getAccessToken();
        setToken(access_token);
      }
      throw new Error('NEW_PASSWORD_REQUIRED');
    }

    if (cognitoUser.challengeName) {
      console.error(
        `Unable to login, because challenge "${cognitoUser.challengeName}" is mandated and we did not handle this case.`
      );
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, refresh_token } = await getAccessToken();
    setToken(access_token);
    setRefreshToken(refresh_token);

    const user = await getUserInformationByEmail({
      email: cognitoUser.attributes.email
    });

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user.id,
          userName: user.userName,
          picture: user.picture,
          email: user.email,
          name: user.name,
          familyName: user.familyName,
          phoneNumber: user.phoneNumber,
          jobPosition: user.jobPosition,
          role: user.role,
          roleId: user.roleId,
          clients: user?.clients || [new NullClients()]
        }
      }
    });
    return null;
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (data: IRegister): Promise<void> => {
    await signUp({
      clients: data.companyId ? [{ clientId: data.companyId }] : data.clients,
      username: data.email,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      familyName: data.familyName,
      picture: data.picture,
      roleId: data.role,
      jobPosition: data.jobPosition,
      permissions: data.permissions
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const updateUser = async (user: IUser, propagateChanges?: boolean): Promise<void> => {
    await updateUserInformation({
      id: user.id,
      clients: user?.clients || null,
      username: user.email,
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      familyName: user.familyName,
      roleId: user?.roleId || null,
      jobPosition: user.jobPosition
    });
    if (propagateChanges) {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user: {
            id: user.id,
            userName: user.userName,
            picture: user.picture,
            email: user.email,
            name: user.name,
            familyName: user.familyName,
            phoneNumber: user.phoneNumber,
            jobPosition: user.jobPosition,
            role: user.role,
            roleId: user.roleId,
            clients: user?.clients || [new NullClients()]
          }
        }
      });
    }
  };

  const updatePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, oldPassword, newPassword);
    dispatch({
      type: 'UPDATE_PASSWORD'
    });
  };

  const updatePicture = async (url: string, user: IUser, propagateChanges?: boolean): Promise<void> => {
    await updateUserInformation({
      id: user.id,
      clients: user?.clients || [new NullClients()],
      username: user.email,
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      familyName: user.familyName,
      picture: url,
      roleId: user?.roleId || null,
      jobPosition: user.jobPosition
    });
    if (propagateChanges) {
      dispatch({
        type: 'UPDATE_PICTURE',
        payload: {
          user: {
            id: user.id,
            userName: user.userName,
            picture: url,
            email: user.email,
            name: user.name,
            familyName: user.familyName,
            phoneNumber: user.phoneNumber,
            jobPosition: user.jobPosition,
            role: user.role,
            roleId: user.roleId,
            clients: user?.clients || [new NullClients()]
          }
        }
      });
    }
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await forgotPassword({
      email: username
    });
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username: string, newPassword: string): Promise<void> => {
    await resetPassword({
      email: username,
      password: newPassword
    });
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  const passwordResetVerify = async (username: string, code: string, newPassword: string): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        updateUser,
        updatePassword,
        updatePicture,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        passwordResetVerify
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
