/* eslint-disable no-mixed-operators */
import { createContext, FC, ReactNode, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { EComponentType, IEvent, IEventBudgetResponse, IEventReconciliation, IEventWorkingBudget, IEventSimpleRecon } from 'types/IEvent';
import { EVENT_STATUS, EVENT_WIZARD_FINANCIAL_OPTIONS_DEFAULT, IEventWizardOption, USER_ROLES } from 'config/constants';
import { EBudgetTemplateType } from 'types/IBudget';
import { reducer } from 'contexts/EventWizardBudgetContext';
import { getRoomQuantitiesByDates } from 'utils/dayDiff';
import useAuth from 'hooks/useAuth';
import {
  IAddRowOfComponentByKey,
  IAddRowOfFoodQuantitieByKey,
  IDeleteFoodQuantitieOfComponentByKey,
  IDeleteLineOfWorkingBudgetByKey,
  IDeleteRowOfComponentByKey,
  IHandleDeleteChangeOrder,
  IToggleProfitMarkupTypeByKey,
  IUpdateChangeOrderComponentByKeyOptions,
  IUpdateComponentByKeyOptions,
  IUpdateReconciliationComponentByKeyOptions,
  IUpdateWorkingBudgetComponentByKeyOptions
} from './types';

export interface State {
  name: string;
  event: IEvent;
  eventBudget: IEventBudgetResponse;
  eventWorkingBudget: IEventWorkingBudget;
  eventSimpleRecon: IEventSimpleRecon;
  eventReconciliation: IEventReconciliation;
  lastAutosaveTimer: string;
  currentFinancialOption: IEventWizardOption;
  financialOptions: IEventWizardOption[];
  currentBudgetType: EBudgetTemplateType;
  budgetTemplateId: number;
}

const initialState: State = {
  event: null,
  eventBudget: null,
  eventWorkingBudget: null,
  eventSimpleRecon: null,
  eventReconciliation: null,
  lastAutosaveTimer: null,
  financialOptions: EVENT_WIZARD_FINANCIAL_OPTIONS_DEFAULT,
  currentFinancialOption: null,
  name: '',
  currentBudgetType: null,
  budgetTemplateId: null
};

interface EventWizardBudgetContextValue extends State {
  updateEventBudget: (eventBudget: IEventBudgetResponse) => void;
  updateEvent: (event: IEvent) => void;
  updateEventWorkingBudget: (event: IEventWorkingBudget) => void;
  updateEventSimpleRecon: (event: IEventSimpleRecon) => void;
  updateEventReconciliation: (event: IEventReconciliation) => void;
  updateLastAutosaveTimer: (lastAutosaveTimer: string) => void;
  updateCurrentFinancialOption: (option: IEventWizardOption) => void;
  updateEventByKey: (key: string, value: any) => void;
  updateEventBudgetDibursementsByKey: (key: string, value: any) => void;
  updateEventBudgetProductionByKey: (key: string, value: any) => void;
  updateEventBudgetMarketingAndAudienceGenerationByKey: (key: string, value: any) => void;
  updateEventBudgetSignageAngGraphicsByKey: (key: string, value: any) => void;
  updateRoomQuantitieByIndexes: (index: number, indexRoomQuantitie: number, value: any) => void;
  setFinancialOptions: (options: IEventWizardOption[]) => void;
  setBudgetName: (name: string) => void;
  setBudgetType: (type: EBudgetTemplateType) => void;
  setBudgetTemplateId: (id: number) => void;
  checkForRequirements: () => Error | void;
  addManagementFee: () => void;
  toggleProfitMarkupManagementFee: (index: number) => void;
  toggleProfitMarkupManagementFee2: () => void;
  handleDeleteManagementFee: (index: number) => void;
  updateManagementFee: (key: string, value: string | number | boolean, indexManagementFee: number) => void;
  addRowOfFoodQuantitieByKey: (options: IAddRowOfFoodQuantitieByKey) => void;
  addRowOfComponentByKey: (options: IAddRowOfComponentByKey) => void;
  udpateComponentByKey: (options: IUpdateComponentByKeyOptions) => void;
  udpateWorkingBudgetComponentByKey: (options: IUpdateWorkingBudgetComponentByKeyOptions) => void;
  udpateReconciliationComponentByKey: (options: IUpdateReconciliationComponentByKeyOptions) => void;
  udpateChangeOrderComponentByKey: (options: IUpdateChangeOrderComponentByKeyOptions) => void;
  handleDeleteChangeOrder: (options: IHandleDeleteChangeOrder) => void;
  udpateFoodQuantitieComponentByKey: (options: IUpdateComponentByKeyOptions) => void;
  handleDeleteRowOfComponentByKey: ({ component, key, index }: IDeleteRowOfComponentByKey) => void;
  handleDeleteLineOfWorkingBudgetByKey: ({
    component,
    key,
    indexChangeOrder,
    indexLine
  }: IDeleteLineOfWorkingBudgetByKey) => void;
  handleDeleteFoodQuantitieOfComponentByKey: ({ component, key, index }: IDeleteFoodQuantitieOfComponentByKey) => void;
  toggleProfitMarkupTypeByKey: (options: IToggleProfitMarkupTypeByKey) => void;
}

export const EventWizardBudgetContext = createContext<EventWizardBudgetContextValue>({
  event: null,
  eventBudget: null,
  eventWorkingBudget: null,
  eventSimpleRecon: null,
  eventReconciliation: null,
  updateEvent: () => null,
  updateEventBudget: () => null,
  updateEventReconciliation: () => null,
  updateEventWorkingBudget: () => null,
  updateEventSimpleRecon: () => null,
  updateLastAutosaveTimer: () => null,
  updateCurrentFinancialOption: () => null,
  updateEventByKey: () => null,
  updateEventBudgetDibursementsByKey: () => null,
  updateEventBudgetProductionByKey: () => null,
  updateEventBudgetMarketingAndAudienceGenerationByKey: () => null,
  updateEventBudgetSignageAngGraphicsByKey: () => null,
  updateRoomQuantitieByIndexes: () => null,
  setFinancialOptions: () => null,
  toggleProfitMarkupManagementFee: () => null,
  toggleProfitMarkupManagementFee2: () => null,
  handleDeleteManagementFee: () => null,
  updateManagementFee: () => null,
  addManagementFee: () => null,
  setBudgetName: () => null,
  setBudgetType: () => null,
  setBudgetTemplateId: () => null,
  lastAutosaveTimer: null,
  currentFinancialOption: null,
  financialOptions: null,
  name: null,
  currentBudgetType: null,
  budgetTemplateId: null,
  udpateFoodQuantitieComponentByKey: () => null,
  checkForRequirements: () => null,
  addRowOfFoodQuantitieByKey: () => null,
  addRowOfComponentByKey: () => null,
  udpateComponentByKey: () => null,
  udpateWorkingBudgetComponentByKey: () => null,
  udpateReconciliationComponentByKey: () => null,
  udpateChangeOrderComponentByKey: () => null,
  handleDeleteChangeOrder: () => null,
  handleDeleteRowOfComponentByKey: () => null,
  handleDeleteLineOfWorkingBudgetByKey: () => null,
  handleDeleteFoodQuantitieOfComponentByKey: () => null,
  toggleProfitMarkupTypeByKey: () => null
});

interface IEventWizardBudgetProviderProps {
  children: ReactNode;
}

export const EventWizardBudgetProvider: FC<IEventWizardBudgetProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  const updateEvent = (event: IEvent): void =>
    dispatch({
      type: 'UPDATE_EVENT',
      payload: {
        event
      }
    });

  const updateEventBudget = (eventBudget: IEventBudgetResponse): void =>
    dispatch({
      type: 'UPDATE_EVENT_BUDGET',
      payload: {
        eventBudget
      }
    });

  const updateEventWorkingBudget = (eventWorkingBudget: IEventWorkingBudget): void =>
    dispatch({
      type: 'UPDATE_EVENT_WORKING_BUDGET',
      payload: {
        eventWorkingBudget
      }
    });

  const updateEventSimpleRecon = (eventSimpleRecon: IEventSimpleRecon): void =>
    dispatch({
      type: 'UPDATE_EVENT_SIMPLE_RECON',
      payload: {
        eventSimpleRecon
      }
    });

  const updateEventReconciliation = (eventReconciliation: IEventReconciliation): void =>
    dispatch({
      type: 'UPDATE_EVENT_RECONCILIATION',
      payload: {
        eventReconciliation
      }
    });

  const updateLastAutosaveTimer = (lastAutosaveTimer: string): void =>
    dispatch({
      type: 'UPDATE_LAST_AUTOSAVE_TIMER',
      payload: {
        lastAutosaveTimer
      }
    });

  const updateCurrentFinancialOption = (option: IEventWizardOption): void =>
    dispatch({
      type: 'UPDATE_FINANCIAL_OPTION',
      payload: {
        option
      }
    });

  const updateEventByKey = (key: string, value: any): void => {
    if (state.event.eventStates[0].status.toLowerCase() !== EVENT_STATUS.SIGNED.toLowerCase()) {
      dispatch({
        type: 'UPDATE_EVENT_BY_KEY',
        payload: {
          key,
          value
        }
      });
      return;
    }

    if (
      state.event.eventStates[0].status.toLowerCase() === EVENT_STATUS.SIGNED.toLowerCase() &&
      user.role.id === USER_ROLES.ADMIN.id &&
      key === 'eventStates'
    ) {
      dispatch({
        type: 'UPDATE_EVENT_BY_KEY',
        payload: {
          key,
          value
        }
      });
    }
  };

  const updateRoomQuantitieByIndexes = (indexRoomType: number, indexRoomQuantitie: number, value: any) => {
    dispatch({
      type: 'UPDATE_ROOM_QUANTITIE_BY_INDEX',
      payload: {
        indexRoomType,
        indexRoomQuantitie,
        value
      }
    });
  };

  const setFinancialOptions = (options: IEventWizardOption[]) => {
    dispatch({
      type: 'UPDATE_FINANCIAL_OPTIONS',
      payload: {
        options
      }
    });
  };

  const setBudgetName = (name: string) => {
    dispatch({
      type: 'UPDATE_BUDGET_NAME',
      payload: {
        name
      }
    });
  };

  const setBudgetType = (type: EBudgetTemplateType) => {
    dispatch({
      type: 'UPDATE_BUDGET_TYPE',
      payload: {
        type
      }
    });
  };

  const setBudgetTemplateId = (id: number) => {
    dispatch({
      type: 'UPDATE_BUDGET_TEMPLATE_ID',
      payload: {
        id
      }
    });
  };

  const updateEventBudgetDibursementsByKey = (key: string, value: any): void => {
    dispatch({
      type: 'UPDATE_EVENT_BUDGET_DISBURSEMENTS_BY_KEY',
      payload: {
        key,
        value
      }
    });
    if (key === 'managementFeePerHead') {
      dispatch({
        type: 'UPDATED_HONORARIA_TYPE_FINAL_PRICE',
        payload: {
          index: 0
        }
      });
    }
  };

  const updateEventBudgetProductionByKey = (key: string, value: any): void => {
    dispatch({
      type: 'UPDATE_EVENT_BUDGET_PRODUCTION_BY_KEY',
      payload: {
        key,
        value
      }
    });
  };

  const updateEventBudgetMarketingAndAudienceGenerationByKey = (key: string, value: any): void => {
    dispatch({
      type: 'UPDATE_EVENT_BUDGET_PRODUCTION_BY_KEY',
      payload: {
        key,
        value
      }
    });
  };

  const updateEventBudgetSignageAngGraphicsByKey = (key: string, value: any): void => {
    dispatch({
      type: 'UPDATE_EVENT_SIGNAGE_AND_GRAPHICS_BY_KEY',
      payload: {
        key,
        value
      }
    });
  };

  const toggleProfitMarkupManagementFee = (index: number) => {
    dispatch({
      type: 'TOGGLE_MARKUP_TYPE_MANAGEMENT_FEE',
      payload: {
        index
      }
    });
    dispatch({
      type: 'UPDATED_MANAGEMENT_FEE_FINAL_PRICE',
      payload: {
        index
      }
    });
  };

  const toggleProfitMarkupManagementFee2 = () => {
    dispatch({
      type: 'TOGGLE_MARKUP_TYPE_MANAGEMENT_FEE2'
    });
    dispatch({
      type: 'UPDATED_MANAGEMENT_FEE_FINAL_PRICE2'
    });
  };

  const handleDeleteManagementFee = (index: number) => {
    dispatch({
      type: 'DELETE_MANAGEMENT_FEE',
      payload: {
        index
      }
    });
  };

  const updateManagementFee = (key: string, value: string | number | boolean, index: number) => {
    dispatch({
      type: 'UPDATE_MANAGEMENT_FEE',
      payload: {
        index,
        key,
        value
      }
    });
    if (key === 'perItemFee' || key === 'quantity') {
      dispatch({
        type: 'UPDATED_MANAGEMENT_FEE_FINAL_PRICE',
        payload: {
          index
        }
      });
    }
  };

  const addManagementFee = () =>
    dispatch({
      type: 'ADD_MANAGEMENT_FEE',
      payload: {
        index: null
      }
    });

  const checkForComissionableRoomType = () =>
    state?.eventBudget?.eventBudgetAccommodation?.roomTypes?.find((roomType) => roomType.isCommisionable);

  const checkForComissionableAudioVisual = () =>
    state?.eventBudget?.eventBudgetProduction?.audioVisuals?.find((audioVisual) => audioVisual.isCommisionable);

  const checkForRequirements = () => {
    if (
      state.currentFinancialOption.type === EComponentType.ROOM_TYPES &&
      checkForComissionableRoomType() &&
      (!state.eventBudget.eventBudgetAccommodation.commissionRate ||
        Number(state.eventBudget?.eventBudgetAccommodation?.commissionRate) === 0)
    ) {
      throw Error('Commission is necessary');
    }

    if (
      state.currentFinancialOption.type === EComponentType.AUDIO_VISUAL &&
      checkForComissionableAudioVisual() &&
      (!state.eventBudget.eventBudgetProduction.commissionRate ||
        Number(state.eventBudget?.eventBudgetProduction?.commissionRate) === 0) &&
      (!state.eventBudget.eventBudgetProduction.commissionFlatRate ||
        Number(state.eventBudget?.eventBudgetProduction?.commissionFlatRate) === 0)
    ) {
      throw Error('Commission rate or commission flat rate is necessary');
    }
  };

  const toggleProfitMarkupTypeByKey = ({ components, key, index, recalculatePrices }: IToggleProfitMarkupTypeByKey) => {
    components.forEach((component, componentIterationIndex) => {
      dispatch({
        type: 'TOGGLE_MARKUP_TYPE_BY_KEY',
        payload: {
          component,
          key,
          index
        }
      });
      if (recalculatePrices[componentIterationIndex].shouldRecalculate) {
        dispatch({
          type: recalculatePrices[componentIterationIndex].component,
          payload: {
            index
          }
        });
      }
    });
  };

  const handleDeleteRowOfComponentByKey = ({ component, key, index }: IDeleteRowOfComponentByKey) => {
    dispatch({
      type: 'DELETE_ROW_OF_COMPONENT_BY_KEY',
      payload: {
        component,
        key,
        index
      }
    });
  };

  const handleDeleteFoodQuantitieOfComponentByKey = ({
    component,
    key,
    index
  }: IDeleteFoodQuantitieOfComponentByKey) => {
    dispatch({
      type: 'DELETE_FOOD_QUANTITIE_COMPONENT_BY_KEY',
      payload: {
        component,
        key,
        index
      }
    });
  };

  const udpateFoodQuantitieComponentByKey = ({
    component,
    key,
    attribute,
    value,
    index,
    recalculatePrices
  }: IUpdateComponentByKeyOptions) => {
    dispatch({
      type: 'UPDATE_FOOD_QUANTITIE_COMPONENT_BY_KEY',
      payload: {
        component,
        key,
        attribute,
        index,
        value
      }
    });

    if (recalculatePrices) {
      recalculatePrices.forEach((recalculatePrice) => {
        if (recalculatePrice.shouldRecalculate) {
          dispatch({
            type: recalculatePrice.component,
            payload: {
              index
            }
          });
        }
      });
    }
  };

  const udpateComponentByKey = ({
    component,
    key,
    attribute,
    value,
    index,
    recalculatePrices
  }: IUpdateComponentByKeyOptions) => {
    dispatch({
      type: 'UPDATE_COMPONENT_BY_KEY',
      payload: {
        component,
        key,
        attribute,
        index,
        value
      }
    });
    if (recalculatePrices) {
      recalculatePrices.forEach((recalculatePrice) => {
        if (recalculatePrice.shouldRecalculate) {
          dispatch({
            type: recalculatePrice.component,
            payload: {
              index
            }
          });
        }
      });
    }
  };

  const udpateWorkingBudgetComponentByKey = ({
    component,
    attribute,
    value,
    index,
    key,
    recalculatePrices
  }: IUpdateWorkingBudgetComponentByKeyOptions) => {
    if (state.event.eventStates[0].status.toLowerCase() !== EVENT_STATUS.RECONCILED.toLowerCase()) {
      dispatch({
        type: 'UPDATE_WORKING_BUDGET_COMPONENT_BY_KEY',
        payload: {
          component,
          attribute,
          index,
          value,
          key
        }
      });
      if (recalculatePrices) {
        recalculatePrices.forEach((recalculatePrice) => {
          if (recalculatePrice.shouldRecalculate) {
            dispatch({
              type: recalculatePrice.component,
              payload: {
                index,
                component
              }
            });
          }
        });
      }
    }
  };

  const udpateReconciliationComponentByKey = ({
    value,
    index,
    component
  }: IUpdateReconciliationComponentByKeyOptions) => {
    if (state.event.eventStates[0].status.toLowerCase() === EVENT_STATUS.RECONCILED.toLowerCase()) {
      dispatch({
        type: 'UPDATE_RECONCILIATION_COMPONENT_BY_KEY',
        payload: {
          index,
          value,
          component
        }
      });
    }
  };

  const udpateChangeOrderComponentByKey = ({
    component,
    attribute,
    value,
    index,
    key,
    recalculatePrices
  }: IUpdateChangeOrderComponentByKeyOptions) => {
    if (state.event.eventStates[0].status.toLowerCase() !== EVENT_STATUS.RECONCILED) {
      dispatch({
        type: 'UPDATE_CHANGE_ORDER_COMPONENT_BY_KEY',
        payload: {
          component,
          attribute,
          index,
          value,
          key
        }
      });
      if (recalculatePrices) {
        recalculatePrices.forEach((recalculatePrice) => {
          if (recalculatePrice.shouldRecalculate) {
            dispatch({
              type: recalculatePrice.component as any,
              payload: {
                indexChangeOrder: recalculatePrice.indexChangeOrder,
                indexLine: recalculatePrice.indexLine,
                component
              }
            });
          }
        });
      }
    }
  };

  const handleDeleteChangeOrder = ({ index }: IHandleDeleteChangeOrder) => {
    dispatch({
      type: 'DELETE_CHANGE_ORDER_COMPONENT_BY_INDEX',
      payload: {
        index
      }
    });
  };

  const handleDeleteLineOfWorkingBudgetByKey = ({
    component,
    key,
    indexChangeOrder,
    indexLine
  }: IDeleteLineOfWorkingBudgetByKey) => {
    if (state.event.eventStates[0].status.toLowerCase() !== EVENT_STATUS.RECONCILED.toLowerCase()) {
      dispatch({
        type: 'DELETE_LINE_OF_WORKING_BUDGET_BY_KEY',
        payload: {
          component,
          key,
          indexChangeOrder,
          indexLine
        }
      });
    }
  };

  const addRowOfFoodQuantitieByKey = ({ component, key, rowObject, indexKey }: IAddRowOfFoodQuantitieByKey) =>
    dispatch({
      type: 'ADD_ROW_OF_FOOD_QUANTITIE_BY_KEY',
      payload: {
        component,
        key,
        rowObject,
        indexKey
      }
    });

  const addRowOfComponentByKey = ({ component, key, rowObject }: IAddRowOfComponentByKey) =>
    dispatch({
      type: 'ADD_ROW_OF_COMPONENT_BY_KEY',
      payload: {
        component,
        key,
        rowObject
      }
    });

  useEffect(() => {
    dispatch({
      type: 'RECALCULATE_ROOM_QUANTITIES_DATE',
      payload: {
        newRoomQuantities: getRoomQuantitiesByDates(
          state?.eventBudget?.eventBudgetAccommodation?.startDate,
          state?.eventBudget?.eventBudgetAccommodation?.endDate,
          true
        )
      }
    });
  }, [
    state?.eventBudget?.eventBudgetAccommodation?.startDate,
    state?.eventBudget?.eventBudgetAccommodation?.endDate,
    state?.eventBudget?.eventBudgetAccommodation?.roomTypes?.length
  ]);

  return (
    <EventWizardBudgetContext.Provider
      value={{
        ...state,
        updateEventBudget,
        updateEvent,
        updateEventReconciliation,
        updateEventWorkingBudget,
        updateEventSimpleRecon,
        updateLastAutosaveTimer,
        updateCurrentFinancialOption,
        updateEventByKey,
        updateRoomQuantitieByIndexes,
        toggleProfitMarkupManagementFee,
        toggleProfitMarkupManagementFee2,
        handleDeleteManagementFee,
        updateManagementFee,
        addManagementFee,
        setFinancialOptions,
        setBudgetName,
        setBudgetType,
        setBudgetTemplateId,
        updateEventBudgetDibursementsByKey,
        updateEventBudgetProductionByKey,
        updateEventBudgetMarketingAndAudienceGenerationByKey,
        updateEventBudgetSignageAngGraphicsByKey,
        checkForRequirements,
        addRowOfFoodQuantitieByKey,
        addRowOfComponentByKey,
        udpateComponentByKey,
        udpateFoodQuantitieComponentByKey,
        handleDeleteRowOfComponentByKey,
        handleDeleteFoodQuantitieOfComponentByKey,
        toggleProfitMarkupTypeByKey,
        udpateWorkingBudgetComponentByKey,
        handleDeleteLineOfWorkingBudgetByKey,
        udpateReconciliationComponentByKey,
        udpateChangeOrderComponentByKey,
        handleDeleteChangeOrder
      }}
    >
      {children}
    </EventWizardBudgetContext.Provider>
  );
};

EventWizardBudgetProvider.propTypes = {
  children: PropTypes.node.isRequired
};
