import { fetchAPI } from 'services/http';
import { QUICKSIGHT_EMBEDDED_URL, REPORTS_CLIENTS, REPORTS_CLIENTS_ALL } from 'config/endpoints';
import { IClientUser } from 'types/IClients';
import { IFetchOptions, EMethods } from './types';

const optionsQuickSightEmbeddedUrl: IFetchOptions = {
  method: EMethods.GET,
  URL: QUICKSIGHT_EMBEDDED_URL,
};

const optionsGetClients: IFetchOptions = {
  method: EMethods.GET,
  URL: REPORTS_CLIENTS,
};

const optionsGetAllClients: IFetchOptions = {
  method: EMethods.GET,
  URL: REPORTS_CLIENTS_ALL,
};

const getEmbeddedUrlQuickSight = (): Promise<unknown | Error> => fetchAPI(optionsQuickSightEmbeddedUrl);

const getReportClients = (params: any): Promise<IClientUser[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetClients,
    params
  };
  return fetchAPI(newOptions);
};

const getAllReportClients = (params: any): Promise<IClientUser[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetAllClients,
    params
  };
  return fetchAPI(newOptions);
};
export { getEmbeddedUrlQuickSight, getReportClients, getAllReportClients };
