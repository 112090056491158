import { fetchAPI } from 'services/http';
import {
  COGNITO_USER_URL,
  COGNITO_USER_URL_RESET_PASSWORD,
  COGNITO_USER_URL_BY_EMAIL,
  COGNITO_USER_URL_FORGOT_PASSWORD,
  COGNITO_USER_ACTIVE_URL,
  COGNITO_USER_FULL_DELETE_URL
} from 'config/endpoints';
import { IUsersResponse, IUser } from 'types/IUser';
import { IFetchOptions, EMethods } from './types';

const optionsSignUp: IFetchOptions = {
  method: EMethods.POST,
  URL: COGNITO_USER_URL
};

const optionsGetUsers: IFetchOptions = {
  method: EMethods.GET,
  URL: COGNITO_USER_URL
};

const optionsGetUserInformationByEmail: IFetchOptions = {
  method: EMethods.GET,
  URL: COGNITO_USER_URL_BY_EMAIL
};

const optionsGetUserInformationById: IFetchOptions = {
  method: EMethods.GET,
  paramInUrlLine: true,
  URL: COGNITO_USER_URL
};

const optionsUpdateUser: IFetchOptions = {
  method: EMethods.PUT,
  URL: COGNITO_USER_URL
};

const optionsResetPassword: IFetchOptions = {
  method: EMethods.POST,
  URL: COGNITO_USER_URL_RESET_PASSWORD
};

const optionsForgotPassword: IFetchOptions = {
  method: EMethods.POST,
  URL: COGNITO_USER_URL_FORGOT_PASSWORD
};

const optionsDeleteUser: IFetchOptions = {
  method: EMethods.DELETE,
  URL: COGNITO_USER_URL
};

const signUp = (body: any): Promise<void> => {
  const newOptions: IFetchOptions = {
    ...optionsSignUp,
    body
  };
  return fetchAPI(newOptions);
};

const getUserInformationByEmail = (params: any): Promise<IUser> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUserInformationByEmail,
    params
  };
  return fetchAPI(newOptions);
};

const getUserInformationById = (params: any): Promise<IUser> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUserInformationById,
    params
  };
  return fetchAPI(newOptions);
};

const getUsers = (params: any): Promise<IUsersResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUsers,
    params
  };
  return fetchAPI(newOptions);
};

const getUsersByRoleAndClient = (params: any): Promise<IUser[]> => {
  const newOptions: IFetchOptions = {
    ...optionsGetUsers,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const updateUserInformation = (body: any): Promise<IUsersResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateUser,
    body
  };
  return fetchAPI(newOptions);
};

const deleteUser = (params: any): Promise<void> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteUser,
    params
  };
  return fetchAPI(newOptions);
};

const forgotPassword = (body: any): Promise<IUsersResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsForgotPassword,
    body
  };
  return fetchAPI(newOptions);
};

const resetPassword = (body: any): Promise<void> => {
  const newOptions: IFetchOptions = {
    ...optionsResetPassword,
    body
  };
  return fetchAPI(newOptions);
};

const optionsActiveUser: IFetchOptions = {
  method: EMethods.PUT,
  URL: COGNITO_USER_ACTIVE_URL
};

const activeUser = (params: any): Promise<void> => {
  const newOptions: IFetchOptions = {
    ...optionsActiveUser,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsFullDeleteUser: IFetchOptions = {
  method: EMethods.DELETE,
  URL: COGNITO_USER_FULL_DELETE_URL
};

const fullDeleteUser = (params: any): Promise<void> => {
  const newOptions: IFetchOptions = {
    ...optionsFullDeleteUser,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

export {
  signUp,
  getUserInformationByEmail,
  getUserInformationById,
  getUsers,
  updateUserInformation,
  deleteUser,
  forgotPassword,
  resetPassword,
  getUsersByRoleAndClient,
  activeUser,
  fullDeleteUser
};
