import { Autocomplete, TextField } from '@material-ui/core';

const CheckboxesTags = ({
  options,
  optionsSelected,
  setOptionsSelected,
  disabled,
  label
}: {
  options: any[];
  optionsSelected: any[];
  setOptionsSelected: any;
  disabled?: boolean;
  label?: string;
}) => (
  <Autocomplete
    disabled={disabled}
    multiple
    id="checkboxes-tags"
    value={optionsSelected}
    onChange={(_, newValue) => {
      if (newValue) {
        setOptionsSelected(newValue.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i));
      }
    }}
    options={options}
    disableCloseOnSelect
    getOptionLabel={(option) => option?.name || option}
    fullWidth
    renderInput={(params) => (
      <TextField {...params} variant="outlined" label={label || 'Organizations'} placeholder="Add" />
    )}
  />
);

export default CheckboxesTags;
