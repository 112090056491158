import { StrictMode } from 'react';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { EventWizardBudgetProvider } from 'contexts/EventWizardBudgetContext';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { AuthProvider } from './contexts/AmplifyContext';
import { SettingsProvider } from './contexts/SettingsContext';
import AMI from './AMI';

const AMIApp = (
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <EventWizardBudgetProvider>
                  <AMI />
                </EventWizardBudgetProvider>
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>
);

render(AMIApp, document.getElementById('root'));
