import { EVENT_STATUS } from 'config/constants';
import { useEffect, useState } from 'react';
import {
  getEventInformationById,
  getEventScopes,
  getEventScopesDetail,
  getEventBudgetInformationById,
  getEventWorkingBudgetInformationById,
  getEventSimpleReconInformationById,
  getEventReconciliationInformationById,
  getEventAttachFilesById,
  HTTP_ERRORS
} from 'services/http';
import {
  IAttachFile,
  IEvent,
  IEventBudgetResponse,
  IEventReconciliation,
  IEventWorkingBudget,
  IEventSimpleRecon,
  IScopeResponse,
  EventSimpleReconNull
} from 'types/IEvent';

export const useGetEventById = (eventId: string): { event: IEvent; isLoading: boolean } => {
  const [event, setEvent] = useState<IEvent>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getClients = async () => {
    try {
      setIsLoading(true);
      const response = await getEventInformationById(eventId);
      setEvent(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (eventId) getClients();
  }, []);
  return { event, isLoading };
};

export const useGetEventAttachFilesById = (
  eventId: string
): { eventAttachFiles: IAttachFile[]; isLoading: boolean } => {
  const [eventAttachFiles, setEventAttachFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAttachFiles = async () => {
    try {
      setIsLoading(true);
      const response = await getEventAttachFilesById(eventId);
      setEventAttachFiles(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (eventId) getAttachFiles();
  }, []);
  return { eventAttachFiles, isLoading };
};

export const useGetEventScope = (): { scopes: string[]; isLoading: boolean } => {
  const [scopes, setScopes] = useState<string[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getScopes = async () => {
    try {
      setIsLoading(true);
      const response = await getEventScopes();
      setScopes(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getScopes();
  }, []);
  return { scopes, isLoading };
};

export const useGetEventScopeDetail = (): { scopes: IScopeResponse[]; isLoading: boolean } => {
  const [scopes, setScopes] = useState<IScopeResponse[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getScopes = async () => {
    try {
      setIsLoading(true);
      const response = await getEventScopesDetail();
      setScopes(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getScopes();
  }, []);
  return { scopes, isLoading };
};

export const useGetEventBudgetById = (
  eventId: string | number
): { eventBudget: IEventBudgetResponse; isLoading: boolean; hasError: boolean } => {
  const [eventBudget, setEventBudget] = useState<IEventBudgetResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getEventBudget = async () => {
    try {
      setIsLoading(true);
      const response = await getEventBudgetInformationById(eventId);
      setEventBudget(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (eventId) getEventBudget();
  }, []);
  return { eventBudget, isLoading, hasError };
};

export const useGetWorkingEventBudgetById = (
  eventId: string | number,
  status: string
): { eventWorkingBudget: IEventWorkingBudget; isLoading: boolean; hasError: boolean } => {
  const [eventWorkingBudget, setEventWorkingBudget] = useState<IEventWorkingBudget>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getEventWorkingBudget = async () => {
    try {
      setIsLoading(true);
      const response = await getEventWorkingBudgetInformationById(eventId);
      setEventWorkingBudget(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (
      eventId &&
      status &&
      (status === EVENT_STATUS.SIGNED || status === EVENT_STATUS.RECONCILED || status === EVENT_STATUS.APPROVED)
    ) {
      getEventWorkingBudget();
    }
  }, [status]);
  return { eventWorkingBudget, isLoading, hasError };
};

export const useGetSimpleEventReconById = (
  eventId: string | number,
  event: IEvent,
  isSigned: boolean
): { eventSimpleRecon: IEventSimpleRecon; isLoading: boolean; hasError: boolean } => {
  const status = event?.eventStates[0].status;
  const [eventSimpleRecon, setEventSimpleRecon] = useState<IEventSimpleRecon>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getEventSimpleRecon = async () => {
    try {
      setIsLoading(true);
      const response = await getEventSimpleReconInformationById(eventId);
      setEventSimpleRecon(response);
      setIsLoading(false);
    } catch (error) {
      if (error.message === HTTP_ERRORS.NOT_FOUND && isSigned) {
        setIsLoading(false);
        const newSimpleRecon = new EventSimpleReconNull();
        newSimpleRecon.event = event;
        newSimpleRecon.eventId = Number(eventId) || null;
        setEventSimpleRecon(newSimpleRecon);
      } else {
        setIsLoading(false);
        setHasError(true);
        throw Error(error);
      }
    }
  };
  const possibleStates = [EVENT_STATUS.APPROVED, EVENT_STATUS.RECONCILED, EVENT_STATUS.SIGNED];

  useEffect(() => {
    if (eventId && status && possibleStates.includes(status)) {
      getEventSimpleRecon();
    }
  }, [status]);
  return { eventSimpleRecon, isLoading, hasError };
};

export const useGetEventReconciliationById = (
  eventId: string | number,
  status
): { eventReconciliation: IEventReconciliation; isLoading: boolean; hasError: boolean } => {
  const [eventReconciliation, setEventReconciliation] = useState<IEventReconciliation>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const getEventReconciliation = async () => {
    try {
      setIsLoading(true);
      const response = await getEventReconciliationInformationById(eventId);
      setEventReconciliation(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (eventId && status && status === EVENT_STATUS.RECONCILED) {
      getEventReconciliation();
    }
  }, [status]);
  return { eventReconciliation, isLoading, hasError };
};
