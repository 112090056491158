import { fetchAPI } from 'services/http';
import { JOBS_GET_INTAKE_FORMS_URL, JOBS_URL } from 'config/endpoints';
import { IJobOrderIntake } from 'types/IJobOrder';
import { IFetchOptions, EMethods } from './types';

const optionsGetJobOrderIntake: IFetchOptions = {
  method: EMethods.GET,
  URL: JOBS_GET_INTAKE_FORMS_URL,
  auth: true
};

const getJobOrderIntake = (): Promise<IJobOrderIntake[]> => fetchAPI(optionsGetJobOrderIntake);

const optionsDeleteJob: IFetchOptions = {
  method: EMethods.DELETE,
  URL: JOBS_URL,
  paramInUrlLine: true,
  auth: true
};

const deleteJobById = (params): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsDeleteJob,
    params
  };
  return fetchAPI(newOptions);
};

export { getJobOrderIntake, deleteJobById };
