import { FC, ReactNode } from 'react';
import FolderOpenIcon from 'icons/FolderOpen';
import ChartSquareBarIcon from 'icons/ChartSquareBar';
import UsersIcon from 'icons/Users';
import { HUBSPOT_SIDEBAR_URL, MODULES, MONDAY_SIDEBAR_URL, ROUTE_PATH, USER_ROLES } from 'config/constants';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';
import UserAdd from 'icons/UserAdd';
import useRoleGuard from 'hooks/useRoleGuard';
import Template from 'icons/Template';
import ExternalLink from 'icons/ExternalLink';
import { Button } from '@material-ui/core';
import NavSection from '../NavSection';

interface DashboardSidebarSectionsProps {
  children?: ReactNode;
}

const DashboardSidebarSections: FC<DashboardSidebarSectionsProps> = () => {
  const { user } = useAuth();
  const { permissions } = useRoleGuard();
  const location = useLocation();
  interface IItemSection {
    title: any;
    path: any;
    icon?: any;
    children?: IItemSection[];
  }

  interface ISection {
    title: any;
    items: IItemSection[];
  }

  const managementSection: ISection[] = [
    {
      title: 'Management',
      items: [
        {
          title: 'Directory',
          path: ROUTE_PATH.USER_MANAGEMENT.USER_LIST,
          icon: <UsersIcon fontSize="small" />
        },
        {
          title: 'Organization',
          path: ROUTE_PATH.CLIENT_MANAGEMENT.CLIENT_LIST,
          icon: <UsersIcon fontSize="small" />
        },
        {
          title: 'Add',
          path: ROUTE_PATH.AUTHENTICATION.REGISTER.ROOT,
          icon: <UserAdd fontSize="small" />,
          children: [
            {
              title: 'AMI User',
              path: ROUTE_PATH.AUTHENTICATION.REGISTER.AMI_USER
            },
            {
              title: 'Add new Meeting Contact',
              path: ROUTE_PATH.AUTHENTICATION.REGISTER.CLIENT_USER
            },
            {
              title: 'Add new Organization',
              path: ROUTE_PATH.AUTHENTICATION.REGISTER.CLIENT
            }
          ]
        }
      ]
    }
  ];

  const menuItems = {
    dashboard: {
      title: 'Dashboard',
      path: ROUTE_PATH.DASHBOARD.ROOT,
      icon: <ChartSquareBarIcon fontSize="small" />
    },
    reports: {
      title: 'Reports',
      path: ROUTE_PATH.REPORT.ROOT,
      icon: <FolderOpenIcon fontSize="small" />
    },
    budgetTeamplates: {
      title: 'Budget Templates',
      path: ROUTE_PATH.DASHBOARD.BUDGET_TEMPLATE.ROOT,
      icon: <Template fontSize="small" />
    }
  };

  const switcherSection: ISection[] = [
    {
      title: 'Switcher',
      items: [
        {
          title: (
            <Button color="inherit" target="_blank" href={HUBSPOT_SIDEBAR_URL}>
              Hubspot
            </Button>
          ),
          path: '',
          icon: <ExternalLink fontSize="small" />
        },
        {
          title: (
            <Button color="inherit" target="_blank" href={MONDAY_SIDEBAR_URL}>
              Monday.com
            </Button>
          ),
          path: '',
          icon: <ExternalLink fontSize="small" />
        }
      ]
    }
  ];

  const menuSection: ISection[] = [
    {
      title: 'Menu',
      items: []
    }
  ];

  let SECTIONS = [];
  if (permissions.read(user, MODULES.DASHBOARD.id)) {
    menuSection[0].items.push(menuItems.dashboard);
  }

  if (permissions.read(user, MODULES.BUDGET_TEMPLATE.id)) {
    menuSection[0].items.push(menuItems.budgetTeamplates);
  }

  if (permissions.read(user, MODULES.REPORTS.id)) {
    menuSection[0].items.push(menuItems.reports);
  }

  SECTIONS = [...SECTIONS, ...menuSection];

  if (permissions.read(user, MODULES.USERS.id)) SECTIONS = [...SECTIONS, ...managementSection];

  const userIsClient = () =>
    user.role.id === USER_ROLES.MANAGER.id ||
    user.role.id === USER_ROLES.ORGANIZER.id ||
    user.role.id === USER_ROLES.VIEWER.id;

  if (!userIsClient()) SECTIONS = [...SECTIONS, ...switcherSection];

  return (
    <>
      {SECTIONS &&
        SECTIONS?.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 3
              }
            }}
            {...section}
          />
        ))}
    </>
  );
};
export default DashboardSidebarSections;
