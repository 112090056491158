export enum EGranType {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token'
}

type IAccessConnectionParams = {
  client_id: string;
  client_secret: string;
  scope: string;
  grant_type: EGranType;
  username: string;
  password: string;
};

export type IRefreshConnectionParams = {
  client_id: string;
  client_secret: string;
  refresh_token: string;
  grant_type: EGranType;
};

export const ACCESS_DEFAULT_PARAMS: IAccessConnectionParams = {
  client_id: window.env.CLIENT_ID,
  client_secret: window.env.CLIENT_SECRET,
  scope: 'offline_access',
  grant_type: EGranType.PASSWORD,
  username: window.env.USERNAME,
  password: window.env.PASSWORD
};

export const REFRESH_DEFAULT_PARAMS: IRefreshConnectionParams = {
  client_id: window.env.CLIENT_ID,
  client_secret: window.env.CLIENT_SECRET,
  grant_type: EGranType.REFRESH_TOKEN,
  refresh_token: ''
};
