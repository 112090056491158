import { Autocomplete, Button, Grid, InputAdornment, TextField } from '@material-ui/core';
import { ProfitMarkup } from 'components/widgets/inputs';
import { numberFormatCurrencyDollar } from 'config/constants';
import CurrencyDollar from 'icons/CurrencyDollar';
import X from 'icons/X';
import Draggable from 'icons/Draggable';
import { FC } from 'react';
import { IFoodDates, IFoodQuantities } from 'types/IEvent';
import { onlyNumbersValidor, twoDecimalsMax } from 'utils/regexValidators';
import { useEventWizardBudget } from 'hooks';
import { isSignedEvent } from '../../../../wizardUtils';

export class FoodDate implements IFoodDates {
  date: string;
  constructor(date: string) {
    this.date = date;
  }
  foodQuantities = [];
}

interface IEventWizardFoodAndBeverageFoodDateItemProps {
  types: string[];
  options: {
    deleteOptions: any;
    updateOptions: any;
  };
  index: number;
  calculateTotalSummatory: () => void;
  foodQuantitieItem: IFoodQuantities;
  indexFoodQuantitie: number;
}

const EventWizardFoodAndBeverageFoodDateItem: FC<IEventWizardFoodAndBeverageFoodDateItemProps> = ({
  foodQuantitieItem,
  indexFoodQuantitie,
  types,
  index,
  options: { deleteOptions, updateOptions },
  calculateTotalSummatory
}) => {
  const {
    udpateFoodQuantitieComponentByKey,
    handleDeleteFoodQuantitieOfComponentByKey,
    event
  } = useEventWizardBudget();
  const isEventSigned = isSignedEvent(event);

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
          <Grid item xs={2}>
            <Draggable />
          </Grid>
          <Grid item xs={10}>
            <Autocomplete
              options={types}
              getOptionLabel={(option: string) => option || ''}
              style={{ width: '90%', marginRight: 10 }}
              onChange={(_, value: string) =>
                udpateFoodQuantitieComponentByKey({
                  ...updateOptions,
                  attribute: 'typeName',
                  value,
                  index,
                  key: indexFoodQuantitie
                })
              }
              id="type"
              value={foodQuantitieItem?.typeName || ''}
              size="small"
              freeSolo
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Type"
                  variant="outlined"
                  {...params}
                  name="type"
                  onChange={(_) =>
                    udpateFoodQuantitieComponentByKey({
                      ...updateOptions,
                      attribute: 'typeName',
                      value: _.target.value,
                      index,
                      key: indexFoodQuantitie
                    })
                  }
                  disabled={isEventSigned}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="quantity"
          value={foodQuantitieItem?.quantity}
          onChange={(_) => {
            if (!_.target.value || onlyNumbersValidor.test(_.target.value)) {
              udpateFoodQuantitieComponentByKey({
                ...updateOptions,
                attribute: 'quantity',
                value: _.target.value,
                index,
                key: indexFoodQuantitie
              });
              calculateTotalSummatory();
            }
          }}
          disabled={isEventSigned}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="baseCost"
          value={foodQuantitieItem?.baseCost}
          onChange={(_) => {
            if (!_.target.value || (onlyNumbersValidor.test(_.target.value) && twoDecimalsMax.test(_.target.value))) {
              udpateFoodQuantitieComponentByKey({
                ...updateOptions,
                attribute: 'baseCost',
                value: _.target.value,
                index,
                key: indexFoodQuantitie
              });
              calculateTotalSummatory();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ transform: 'scale(0.8)', width: '20%', marginLeft: '-10px' }}>
                <CurrencyDollar />
              </InputAdornment>
            )
          }}
          disabled={isEventSigned}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="subtotal"
          value={new Intl.NumberFormat('en', numberFormatCurrencyDollar).format(foodQuantitieItem?.subtotal)}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ transform: 'scale(0.8)', width: '20%', marginLeft: '-10px' }}>
                <CurrencyDollar />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <ProfitMarkup
          handleToogleProfitMarkup={() =>
            udpateFoodQuantitieComponentByKey({
              ...updateOptions,
              index,
              key: indexFoodQuantitie,
              attribute: 'ProfitMarkupType'
            })
          }
          handleOnChange={(_) => {
            if (!_.target.value || (onlyNumbersValidor.test(_.target.value) && twoDecimalsMax.test(_.target.value))) {
              udpateFoodQuantitieComponentByKey({
                ...updateOptions,
                attribute: 'profitMarkup',
                value: _.target.value,
                index,
                key: indexFoodQuantitie
              });
            }
          }}
          value={foodQuantitieItem?.profitMarkup}
          profitMarkupType={foodQuantitieItem?.profitMarkupType}
          disabled={isEventSigned}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="unitFinalBasePrice"
          value={new Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'USD'
          }).format(foodQuantitieItem?.unitFinalBasePrice)}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ transform: 'scale(0.8)', width: '20%', marginLeft: '-10px' }}>
                <CurrencyDollar />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="total"
          value={new Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'USD'
          }).format(foodQuantitieItem?.total)}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ transform: 'scale(0.8)', width: '20%', marginLeft: '-10px' }}>
                <CurrencyDollar />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          onClick={() => {
            handleDeleteFoodQuantitieOfComponentByKey({
              ...deleteOptions,
              index,
              key: indexFoodQuantitie
            });
            calculateTotalSummatory();
          }}
          variant="text"
          disabled={isEventSigned}
        >
          <X />
        </Button>
      </Grid>
    </Grid>
  );
};
export default EventWizardFoodAndBeverageFoodDateItem;
