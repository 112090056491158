import { eventStatusColor } from 'theme';
import { EComponentType, EEventBudgetComponent } from 'types/IEvent';
import { ETabEventWizard } from 'types/ITab';
import { IUser } from 'types/IUser';

export const USER_ROLES = {
  ADMIN: {
    name: 'admin',
    id: 1
  },
  GUEST: {
    name: 'guest',
    id: 0
  },
  CSM: { id: 2, name: 'CSM' },
  ACSM: { id: 3, name: 'ACSM' },
  PLANNER: { id: 4, name: 'Planner' },
  COORDINATOR: { id: 5, name: 'Coordinator' },
  WEBCAST_PRODUCER: { id: 6, name: 'Webcast Producer' },
  MANAGER: {
    id: 7,
    name: 'Manager'
  },
  ORGANIZER: {
    id: 8,
    name: 'Organizer'
  },
  VIEWER: {
    id: 9,
    name: 'Viewer'
  },
  SUPER_CSM: { id: 10, name: 'Super CSM' },
  SUPER_ACSM: { id: 11, name: 'Super ACSM' },
  SUPER_PLANNER: { id: 12, name: 'Super Planner' }
};

export const USER_ROLES_FOR_AUTOCOMPLETE = {
  CSM: { id: 2, name: 'CSM', params: ['CSM', 'Super CSM'] },
  ACSM: { id: 3, name: 'ACSM', params: ['ACSM', 'Super CSM', 'Super ACSM'] },
  PLANNER: { id: 4, name: 'Planner', params: ['Planner', 'Super Planner', 'Super CSM', 'Super ACSM'] },
  COORDINATOR: { id: 5, name: 'Coordinator', params: ['Coordinator'] },
  WEBCAST_PRODUCER: {
    id: 6,
    name: 'Webcast Producer',
    params: ['Webcast Producer']
  },
  MANAGER: {
    id: 7,
    name: 'Manager',
    params: ['Manager']
  },
  ORGANIZER: {
    id: 8,
    name: 'Organizer',
    params: ['Organizer']
  },
  VIEWER: {
    id: 9,
    name: 'Viewer',
    params: ['Viewer']
  }
};

export const USER_ROLES_FOR_MEETING_CONTACT = {
  MANAGER: {
    id: 7,
    name: 'Manager'
  },
  ORGANIZER: {
    id: 8,
    name: 'Organizer'
  },
  VIEWER: {
    id: 9,
    name: 'Viewer'
  }
};

export const USER_TYPES = {
  CLIENT_USER: {
    label: 'ClientUser',
    name: 'Client User'
  },
  AMI_USER: {
    label: 'AmiUser',
    name: 'Ami User'
  }
};

export const TABLE_CONFIG = {
  ROWS_PER_PAGE_OPTIONS: [30, 50, 100]
};

export const INBOX_TABLE_CONFIG = {
  ROWS_PER_PAGE_OPTIONS: [30, 50, 100]
};

export const EVENT_STATUS = {
  APPROVED: 'Approved',
  NEW: 'New',
  PROPOSED: 'Proposed',
  SIGNED: 'Signed',
  CXL: 'CXL',
  RECONCILED: 'Reconciled',
  TBD: 'TBD',
  FORECAST: 'Forecast',
  MISSED_OPP: 'Missed Opp',
  INBOX: 'Inbox',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
  DEFAULT: 'N/A'
};

export const EVENT_STATUS_LABEL_MAP = {
  Approved: {
    color: eventStatusColor.Approved,
    text: EVENT_STATUS.APPROVED
  },
  CXL: {
    color: eventStatusColor.CLX,
    text: EVENT_STATUS.CXL
  },
  Reconciled: {
    color: eventStatusColor.Reconciled,
    text: EVENT_STATUS.RECONCILED
  },
  Signed: {
    color: eventStatusColor.Signed,
    text: EVENT_STATUS.SIGNED
  },
  New: {
    color: eventStatusColor.New,
    text: EVENT_STATUS.NEW
  },
  Proposed: {
    color: eventStatusColor.Proposed,
    text: EVENT_STATUS.PROPOSED
  },
  TBD: {
    color: eventStatusColor.TBD,
    text: EVENT_STATUS.TBD
  },
  Forecast: {
    color: eventStatusColor.Forecast,
    text: EVENT_STATUS.FORECAST
  },
  'Missed Opp': {
    color: eventStatusColor.MissedOpp,
    text: EVENT_STATUS.MISSED_OPP
  },
  MissedOpp: {
    color: eventStatusColor.MissedOpp,
    text: EVENT_STATUS.MISSED_OPP
  },
  Cancelled: {
    color: eventStatusColor.Cancelled,
    text: EVENT_STATUS.CANCELLED
  },
  Pending: {
    color: eventStatusColor.Pending,
    text: EVENT_STATUS.PENDING
  },
  Inbox: {
    color: eventStatusColor.Proposed,
    text: EVENT_STATUS.INBOX
  },
  Default: {
    color: eventStatusColor.Proposed,
    text: EVENT_STATUS.DEFAULT
  }
};

export const MONDAY_SIDEBAR_URL = 'https://americanmeetings.monday.com/';
export const HUBSPOT_SIDEBAR_URL = 'https://app.hubspot.com/contacts/4562322/deals/list/view/all/';

export const DEBOUNCE_IDLE_TIME = 800;

export const OVERVIEW_TABS = [
  { label: 'Inbox', value: 'inbox' },
  { label: 'Active Events', value: 'active-events' }
];

export const EVENT_WIZARD_TABS = [
  {
    label: 'Attach File',
    value: ETabEventWizard.ATTACH_FILES
  },
  {
    label: 'Program budget',
    value: ETabEventWizard.PROGRAM_BUDGET
  },
  {
    label: 'Working Budget',
    value: ETabEventWizard.WORKING_BUDGET
  },
  {
    label: 'Simple Reconciliation',
    value: ETabEventWizard.SIMPLE_RECON
  },
  {
    label: 'Reconciliation',
    value: ETabEventWizard.RECONCILIATION
  }
];

export const ACCOUNT_SETTINGS_TABS = [
  { label: 'General', value: 'general' },
  { label: 'Security', value: 'security' }
];
export const USER_SETTINGS_TABS = [{ label: 'General', value: 'general' }];

export const ROUTE_PATH = {
  HOME: '/',
  DASHBOARD: {
    ROOT: '/dashboard',
    INBOX: '/dashboard/inbox',
    ACTIVE_EVENTS: '/dashboard/active-events',
    ACCOUNT: '/dashboard/account',
    EVENT: '/dashboard/event',
    EVENT_BUDGET: '/dashboard/event/wizard',
    BUDGET_TEMPLATE: {
      ROOT: '/dashboard/budget-template',
      CREATION: '/dashboard/budget-template/new-template',
      EDIT: '/dashboard/budget-template/edit'
    }
  },
  AUTHENTICATION: {
    LOGIN: '/authentication/login',
    REGISTER: {
      ROOT: '/management/user/register',
      AMI_USER: '/management/user/register/ami-user',
      CLIENT_USER: '/management/user/register/client-user',
      CLIENT: '/management/client/register/ami-client'
    },
    PASSWORD_MANAGEMENT: {
      RESET: '/authentication/password-reset',
      RESET_VERIFY: '/authentication/password-reset/verify',
      RECOVERY: '/authentication/password-recovery',
      VERIFY_CODE: '/authentication/verify-code'
    }
  },
  USER_MANAGEMENT: {
    ROOT: '/management/user',
    USER_LIST: '/management/user/list'
  },
  CLIENT_MANAGEMENT: {
    ROOT: '/management/client',
    CLIENT_LIST: '/management/client/list'
  },
  REPORT: {
    ROOT: '/reports',
    CLIENT_REPORT: '/reports/client'
  },
  PROFILE: ''
};

export const DATE_FORMAT = 'MM/dd/yy';
export const QUICK_SIGHT_DATE_FORMAT = 'YYYY-MM-DD';
export const ROOM_TYPE_DATE_FORMAT = 'YYYY-MM-DD ';
export const ROOM_TYPE_DATE_FORMAT_SHORT = 'MM-DD-YY';
export const WORKING_BUDGET_DESCRIPTOR_DATE_FORMAT_SHORT = 'MM/DD';

export const MODULES = {
  USERS: {
    id: 1,
    name: 'Users',
    roles: [USER_ROLES.ADMIN],
    permissions: {
      update: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.USERS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].edit;
        return false;
      },
      create: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.USERS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].create;
        return false;
      },
      delete: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.USERS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].delete;
        return false;
      },
      read: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.USERS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].read;
        return false;
      }
    },
    route: ROUTE_PATH.USER_MANAGEMENT.USER_LIST
  },
  DASHBOARD: {
    id: 2,
    name: 'Dashboard',
    roles: [
      USER_ROLES.ADMIN,
      USER_ROLES.CSM,
      USER_ROLES.ACSM,
      USER_ROLES.PLANNER,
      USER_ROLES.COORDINATOR,
      USER_ROLES.WEBCAST_PRODUCER,
      USER_ROLES.SUPER_CSM,
      USER_ROLES.SUPER_ACSM,
      USER_ROLES.SUPER_PLANNER
    ],
    permissions: {
      update: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.DASHBOARD.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].edit;
        return false;
      },
      create: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.DASHBOARD.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].create;
        return false;
      },
      delete: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.DASHBOARD.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].delete;
        return false;
      },
      read: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.DASHBOARD.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].read;
        return false;
      }
    },
    route: ROUTE_PATH.DASHBOARD.ROOT
  },
  REPORTS: {
    id: 3,
    name: 'Reports',
    roles: [
      USER_ROLES.ADMIN,
      USER_ROLES.CSM,
      USER_ROLES.ACSM,
      USER_ROLES.MANAGER,
      USER_ROLES.ORGANIZER,
      USER_ROLES.VIEWER,
      USER_ROLES.SUPER_CSM,
      USER_ROLES.SUPER_ACSM,
      USER_ROLES.SUPER_PLANNER
    ],
    permissions: {
      update: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.REPORTS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].edit;
        return false;
      },
      create: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.REPORTS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].create;
        return false;
      },
      delete: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.REPORTS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].delete;
        return false;
      },
      read: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.REPORTS.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].read;
        return false;
      }
    },
    route: ROUTE_PATH.REPORT.ROOT
  },
  BUDGET_TEMPLATE: {
    id: 4,
    name: 'Budget Template',
    roles: [USER_ROLES.ADMIN],
    permissions: {
      update: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.BUDGET_TEMPLATE.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].edit;
        return false;
      },
      create: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.BUDGET_TEMPLATE.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].create;
        return false;
      },
      delete: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.BUDGET_TEMPLATE.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].delete;
        return false;
      },
      read: (user: IUser, moduleId?: number) => {
        const moduleToEvaluate = moduleId || MODULES.BUDGET_TEMPLATE.id;
        const permissionsByModule = user.role.permissions.filter(
          (permission) => permission.moduleId === moduleToEvaluate
        );
        if (permissionsByModule.length) return permissionsByModule[0].read;
        return false;
      }
    },
    route: ROUTE_PATH.DASHBOARD.BUDGET_TEMPLATE.ROOT
  }
};

export const MODULES_DICTIONARY = [
  {
    name: 'dashboard',
    value: MODULES.DASHBOARD
  },
  {
    name: 'user',
    value: MODULES.USERS
  },
  {
    name: 'reports',
    value: MODULES.REPORTS
  },
  {
    name: 'budgetTemplate',
    value: MODULES.BUDGET_TEMPLATE
  }
];

export const TIME_AUTOSAVE_INTERVAL = 30000;

export interface IEventWizardOption {
  name: string;
  type: EComponentType;
  disabled?: boolean;
  budget?: string;
  children?: {
    name: string;
    type: EComponentType;
    disabled?: boolean;
  }[];
}

export const EVENT_WIZARD_FINANCIAL_OPTIONS_DEFAULT: IEventWizardOption[] = [
  {
    name: 'Overview',
    type: EComponentType.OVERVIEW
  },
  {
    name: 'Accommodations',
    type: EComponentType.ACCOMMODATIONS,
    budget: EEventBudgetComponent.eventBudgetAccommodation,
    children: [
      {
        name: 'Dates',
        type: EComponentType.DATES
      },
      {
        name: 'Room Types',
        type: EComponentType.ROOM_TYPES
      },
      {
        name: 'Quantities',
        type: EComponentType.QUANTITIES
      },
      {
        name: 'Service Fees',
        type: EComponentType.SERVICE_FEES
      }
    ]
  },
  {
    name: 'Food & Beverage',
    type: EComponentType.FOOD_AND_BEVERAGE,
    budget: EEventBudgetComponent.eventBudgetFoodAndBeverage,
    children: [
      {
        name: 'Quantities',
        type: EComponentType.FOOD_AND_BEVERAGE_QUANTITIES
      },
      {
        name: 'Taxes & Fees',
        type: EComponentType.FOOD_AND_BEVERAGE_TAXES_AND_FEES
      }
    ]
  },
  {
    name: 'Transportation',
    type: EComponentType.TRANSPORTATION,
    budget: EEventBudgetComponent.eventBudgetTransportation,
    children: [
      {
        name: 'Ground',
        type: EComponentType.TRANSPORTATION_GROUND
      },
      {
        name: 'Air',
        type: EComponentType.TRANSPORTATION_AIR
      }
    ]
  },
  {
    name: 'Disbursements',
    type: EComponentType.DISBURSEMENTS,
    budget: EEventBudgetComponent.eventBudgetDisbursement,
    children: [
      {
        name: 'Per Diem',
        type: EComponentType.PER_DIEM
      },
      {
        name: 'Honoraria',
        type: EComponentType.HONORARIA
      },
      {
        name: 'Expense Reimbursement',
        type: EComponentType.EXPENSE_REIMBURSEMENT
      }
    ]
  },
  {
    name: 'Signage & Graphics',
    type: EComponentType.SIGNAGE_AND_GRAPHICS,
    budget: EEventBudgetComponent.eventBudgetSignageAngGraphics,
    children: [
      {
        name: 'Signage',
        type: EComponentType.SIGNAGE
      },
      {
        name: 'Support Collateral',
        type: EComponentType.SUPPORT_COLLATERAL
      },
      {
        name: 'Creative Development',
        type: EComponentType.CREATIVE_DEVELOPMENT
      }
    ]
  },
  {
    name: 'Event Production',
    type: EComponentType.EVENT_PRODUCTION,
    budget: EEventBudgetComponent.eventBudgetProduction,
    children: [
      {
        name: 'Audio/Visual',
        type: EComponentType.AUDIO_VISUAL
      },
      {
        name: 'Venue',
        type: EComponentType.VENUE
      },
      {
        name: 'Additional Costs',
        type: EComponentType.ADDITIONAL_COSTS
      }
    ]
  },
  {
    name: 'Marketing & Audience Generation',
    type: EComponentType.MARKETING,
    budget: EEventBudgetComponent.eventBudgetMarketingAndAudienceGeneration,
    children: [
      {
        name: 'Direct Marketing',
        type: EComponentType.DIRECT_MARKETING
      },
      {
        name: 'Audience Generation',
        type: EComponentType.AUDIENCE_GENERATION
      },
      {
        name: 'Other',
        type: EComponentType.MARKETING_OTHER
      }
    ]
  },
  {
    name: 'Contract Labor',
    type: EComponentType.CONTRACT_LABOR
  },
  {
    name: 'Shipping Postage',
    type: EComponentType.SHIPPING_POSTAGE
  },
  {
    name: 'Management Fee',
    type: EComponentType.MANAGEMENT_FEE
  },
  {
    name: 'AMI Internal Cost',
    type: EComponentType.AMI_INTERNAL_COST
  }
];

export const EVENT_WIZARD_FINANCIAL_OPTIONS_FOR_BUDGET_TEMPLATE: IEventWizardOption[] = [
  {
    name: 'Accommodations',
    type: EComponentType.ACCOMMODATIONS,
    children: [
      {
        name: 'Dates',
        type: EComponentType.DATES
      },
      {
        name: 'Room Types',
        type: EComponentType.ROOM_TYPES
      },
      {
        name: 'Quantities',
        type: EComponentType.QUANTITIES
      },
      {
        name: 'Service Fees',
        type: EComponentType.SERVICE_FEES
      }
    ]
  },
  {
    name: 'Food & Beverage',
    type: EComponentType.FOOD_AND_BEVERAGE,
    children: [
      {
        name: 'Quantities',
        type: EComponentType.FOOD_AND_BEVERAGE_QUANTITIES
      },
      {
        name: 'Taxes & Fees',
        type: EComponentType.FOOD_AND_BEVERAGE_TAXES_AND_FEES
      }
    ]
  },
  {
    name: 'Transportation',
    type: EComponentType.TRANSPORTATION,
    budget: EEventBudgetComponent.eventBudgetTransportation,
    children: [
      {
        name: 'Ground',
        type: EComponentType.TRANSPORTATION_GROUND
      },
      {
        name: 'Air',
        type: EComponentType.TRANSPORTATION_AIR
      }
    ]
  },
  {
    name: 'Disbursements',
    type: EComponentType.DISBURSEMENTS,
    children: [
      {
        name: 'Per Diem',
        type: EComponentType.PER_DIEM
      },
      {
        name: 'Honoraria',
        type: EComponentType.HONORARIA
      },
      {
        name: 'Expense Reimbursement',
        type: EComponentType.EXPENSE_REIMBURSEMENT
      }
    ]
  },
  {
    name: 'Signage & Graphics',
    type: EComponentType.SIGNAGE_AND_GRAPHICS,
    children: [
      {
        name: 'Signage',
        type: EComponentType.SIGNAGE
      },
      {
        name: 'Support Collateral',
        type: EComponentType.SUPPORT_COLLATERAL
      },
      {
        name: 'Creative Development',
        type: EComponentType.CREATIVE_DEVELOPMENT
      }
    ]
  },
  {
    name: 'Event Production',
    type: EComponentType.EVENT_PRODUCTION,
    children: [
      {
        name: 'Audio/Visual',
        type: EComponentType.AUDIO_VISUAL
      },
      {
        name: 'Venue',
        type: EComponentType.VENUE
      },
      {
        name: 'Additional Costs',
        type: EComponentType.ADDITIONAL_COSTS
      }
    ]
  },
  {
    name: 'Marketing & Audience Generation',
    type: EComponentType.MARKETING,
    children: [
      {
        name: 'Direct Marketing',
        type: EComponentType.DIRECT_MARKETING
      },
      {
        name: 'Audience Generation',
        type: EComponentType.AUDIENCE_GENERATION
      },
      {
        name: 'Other',
        type: EComponentType.MARKETING_OTHER
      }
    ]
  },
  {
    name: 'Contract Labor',
    type: EComponentType.CONTRACT_LABOR
  },
  {
    name: 'Shipping Postage',
    type: EComponentType.SHIPPING_POSTAGE
  },
  {
    name: 'Management Fee',
    type: EComponentType.MANAGEMENT_FEE
  },
  {
    name: 'AMI Internal Cost',
    type: EComponentType.AMI_INTERNAL_COST
  }
];

export enum EDisbursementRows {
  ATTENDEES = 'Attendees',
  CLIENTS = 'Clients',
  STAFF = 'Staff'
}

export enum ETransportationRows {
  AMI_STAFF = 'AMI Staff',
  ATTENDEES = 'Attendees',
  CLIENTS = 'Clients',
  OTHER = 'Other'
}

export enum EDisbursementType {
  PER_DIEM = 'perDiem',
  EXPENSE_REIMBURSEMENT = 'expenseReimbursement'
}

export const DIBURSEMENTS_ROWS: { name: EDisbursementRows }[] = [
  {
    name: EDisbursementRows.ATTENDEES
  },
  {
    name: EDisbursementRows.CLIENTS
  },
  {
    name: EDisbursementRows.STAFF
  }
];

export const TRANSPORTATION_ROWS: { name: ETransportationRows }[] = [
  {
    name: ETransportationRows.AMI_STAFF
  },
  {
    name: ETransportationRows.ATTENDEES
  },
  {
    name: ETransportationRows.CLIENTS
  },
  {
    name: ETransportationRows.OTHER
  }
];

export const numberFormatCurrencyDollar = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
};
