import { useEffect, useState } from 'react';
import { getEventRegions } from 'services/http';

export const useGetRegions = (): { regions: string[]; isLoading: boolean } => {
  const [regions, setRegions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRegions = async () => {
    try {
      setIsLoading(true);
      const response = await getEventRegions();
      setRegions(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getRegions();
  }, []);
  return { regions, isLoading };
};
