import { MONDAY_BACKEND_ENDPOINT } from 'config/endpoints';
import { fetchAPI } from 'services/http';
import { IRoleResponse } from 'types/IRole';
import { EMethods, IFetchOptions } from './types';

const optionsPostEventToMonday: IFetchOptions = {
  method: EMethods.POST,
  URL: MONDAY_BACKEND_ENDPOINT,
};

const postEventToMonday = (body: any): Promise<IRoleResponse> => {
  const newOptionsToMonday: IFetchOptions = {
    ...optionsPostEventToMonday,
    body
  };
  return fetchAPI(newOptionsToMonday);
};

export { postEventToMonday };
