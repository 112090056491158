export interface ITab {
  label: string;
  value: string;
}

export enum ETab {
  GENERAL = 'general',
  SECURITY = 'security'
}

export enum ETabOverview {
  INBOX = 'inbox',
  ACTIVE_EVENTS = 'active-events',
  DEFAULT = ''
}

export enum ETabEventWizard {
  PROGRAM_BUDGET = 'program-budget',
  WORKING_BUDGET = 'working-budget',
  SIMPLE_RECON = 'simple-recon',
  RECONCILIATION = 'reconciliation',
  ATTACH_FILES = 'attach-files'
}
