import { FC } from 'react';
import 'react-phone-input-2/lib/material.css';
import PhoneInput2 from 'react-phone-input-2';

const containerStyle = {
  flexDirection: 'column' as 'column',
  position: 'relative' as 'relative',
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: 'top',
  marginTop: '16px',
  marginBottom: '8px',
  width: '100%',
  backgroundColor: 'transparent'
};

const inputStyles = {
  width: '100%',
  borderRadius: 16,
  backgroundColor: 'transparent'
};

interface IPhoneInputProps {
  handleBlur: any;
  value: any;
  inputProps: {
    name: string;
    type: string;
    required: boolean;
  };
  handleOnChange: any;
}

const PhoneInput: FC<IPhoneInputProps> = ({ handleBlur, inputProps, value, handleOnChange }) => (
  <PhoneInput2
    inputProps={inputProps}
    onBlur={handleBlur}
    onChange={handleOnChange}
    value={value}
    country="us"
    placeholder="Phone Number"
    specialLabel="Phone Number"
    inputStyle={inputStyles}
    containerStyle={containerStyle}
  />
);
export default PhoneInput;
