import { useRef, useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import queryString from 'query-string';
import { ROUTE_PATH } from 'config/constants';

enum EErrorAmplify {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException'
}

const LoginAmplify: FC = () => {
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const { login } = useAuth() as any;
  const { search, state }: any = useLocation();
  const submitButton = useRef<any>(null);

  const initialValues = {
    email: '',
    password: '',
    submit: null
  };

  const { userName, password }: any = queryString.parse(search);

  if (userName && password) {
    initialValues.email = userName;
    initialValues.password = password;
  } else if (state && state?.userName && state?.password) {
    initialValues.email = state.userName;
    initialValues.password = state.password;
  }

  useEffect(() => {
    if (userName && password && submitButton.current) {
      submitButton.current?.click();
    } else if (state && state?.userName && state?.password && submitButton.current) {
      submitButton.current?.click();
    }
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
          await login(values.email, values.password);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);

          if (err.code === EErrorAmplify.USER_NOT_CONFIRMED_EXCEPTION) {
            navigate(ROUTE_PATH.AUTHENTICATION.PASSWORD_MANAGEMENT.VERIFY_CODE, {
              state: {
                username: values.email
              }
            });
            return;
          }

          if (err.message === EErrorAmplify.NEW_PASSWORD_REQUIRED) {
            navigate(ROUTE_PATH.AUTHENTICATION.PASSWORD_MANAGEMENT.RESET, {
              state: {
                userName: values.email,
                password: values.password
              }
            });
            return;
          }

          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              ref={submitButton}
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
