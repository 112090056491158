/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { MODULES_DICTIONARY, ROUTE_PATH } from 'config/constants';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user?.role?.id) {
      const moduleToRedirect = MODULES_DICTIONARY.find((module) => {
        const moduleRoleAdmited = module?.value?.roles?.find((role) => role?.id === user.role.id);
        const moduleReadPermissionsAdmited = moduleRoleAdmited && module?.value?.permissions.read(user);
        if (moduleRoleAdmited && moduleReadPermissionsAdmited) {
          return module;
        }
        return null;
      });
      if (moduleToRedirect) {
        return <Navigate to={moduleToRedirect.value.route} />;
      }
    }

    return <Navigate to={ROUTE_PATH.DASHBOARD.ROOT} />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
