import { Grid, ListItem } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { EEventBudgetComponent, IFoodDates } from 'types/IEvent';
import { useEventWizardBudget } from 'hooks';
import { DragDropContext, Droppable, OnDragEndResponder, DropResult, Draggable } from 'react-beautiful-dnd';
import { isSignedEvent } from 'components/dashboard/wizard/wizardUtils';
import EventWizardFoodAndBeverageFoodDateItem from './EventWizardFoodAndBeverageFoodDateItem';

export class FoodDate implements IFoodDates {
  date: string;
  constructor(date: string) {
    this.date = date;
  }
  foodQuantities = [];
}

interface IEventWizardFoodAndBeverageFoodDateProps {
  foodDate: IFoodDates;
  types: string[];
  options: {
    deleteOptions: any;
    updateOptions: any;
  };
  index: number;
  calculateTotalSummatory: () => void;
  date: string;
}

const EventWizardFoodAndBeverageFoodDate: FC<IEventWizardFoodAndBeverageFoodDateProps> = ({
  foodDate: { foodQuantities },
  types,
  options: { deleteOptions, updateOptions },
  index,
  calculateTotalSummatory,
  date
}) => {
  const { udpateFoodQuantitieComponentByKey, event } = useEventWizardBudget();
  const isEventSigned = isSignedEvent(event);
  useEffect(() => {
    if (date) {
      udpateFoodQuantitieComponentByKey({
        component: EEventBudgetComponent.eventBudgetFoodAndBeverage,
        attribute: 'date',
        value: date,
        index,
        key: null
      });
    }
  }, [date]);

  const onDragEnd: OnDragEndResponder = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination || isEventSigned) return;

    udpateFoodQuantitieComponentByKey({
      component: EEventBudgetComponent.eventBudgetFoodAndBeverage,
      attribute: 'switchOrder',
      value: destination.index,
      index,
      key: String(source.index)
    });
  };

  return (
    <Grid item xs={12}>
      <DragDropContext onDragEnd={onDragEnd} key={`drag-drop-context${date}`}>
        <Droppable droppableId={`droppable-list${date}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {foodQuantities.length > 0 &&
                foodQuantities.map((foodQuantitieItem, indexFoodQuantitie) => (
                  <Draggable draggableId={indexFoodQuantitie + date} index={indexFoodQuantitie}>
                    {(providedDrag) => (
                      <ListItem ref={providedDrag.innerRef} {...providedDrag.draggableProps} {...providedDrag.dragHandleProps}>
                        <EventWizardFoodAndBeverageFoodDateItem
                          foodQuantitieItem={foodQuantitieItem}
                          types={types}
                          options={{ deleteOptions, updateOptions }}
                          index={index}
                          indexFoodQuantitie={indexFoodQuantitie}
                          calculateTotalSummatory={calculateTotalSummatory}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};
export default EventWizardFoodAndBeverageFoodDate;
