import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import LoadingScreen from 'components/LoadingScreen';

import MainLayout from 'components/MainLayout';
import DashboardLayout from 'components/dashboard/DashboardLayout';

import GuestGuard from 'components/GuestGuard';
import AuthGuard from 'components/AuthGuard';
import RoleGuard from 'components/RoleGuard';

import { MODULES } from 'config/constants';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

// Auth
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const PasswordResetVerify = Loadable(lazy(() => import('./pages/authentication/PasswordResetVerify')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Users
const UsersList = Loadable(lazy(() => import('./pages/users/UsersList')));
const User = Loadable(lazy(() => import('./pages/users/User')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Clients
const ClientsList = Loadable(lazy(() => import('./pages/clients/ClientsList')));
const Client = Loadable(lazy(() => import('./pages/clients/Client')));

// Dashboard
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Event = Loadable(lazy(() => import('./pages/dashboard/Event')));
const CreateEvent = Loadable(lazy(() => import('./pages/dashboard/CreateEvent')));
const EventWizard = Loadable(lazy(() => import('./pages/dashboard/EventWizard')));

// Reports
const Reports = Loadable(lazy(() => import('./pages/Reports')));
const Quicksight = Loadable(lazy(() => import('./pages/reports/Quicksight')));

// Budget Template
const BudgetTemplate = Loadable(lazy(() => import('./pages/dashboard/budget/BudgetTemplate')));
const BudgetTemplateCreation = Loadable(lazy(() => import('./pages/dashboard/budget/BudgetTemplateCreation')));
const BudgetTemplateEdit = Loadable(lazy(() => import('./pages/dashboard/budget/BudgetTemplateEdit')));

// Other
const Home = Loadable(lazy(() => import('./pages/Home')));
const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'password-reset/verify',
        element: <PasswordResetVerify />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <RoleGuard
          currentModule={MODULES.DASHBOARD.id}
          roles={MODULES.DASHBOARD.roles}
          permissions={MODULES.DASHBOARD.permissions}
        >
          <DashboardLayout />
        </RoleGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/:id',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'event/',
        element: <CreateEvent />
      },
      {
        path: 'event/:id',
        element: <Event />
      },
      {
        path: 'event/wizard/:id',
        element: <EventWizard />
      },
      {
        path: 'budget-template',
        element: <BudgetTemplate />
      },
      {
        path: 'budget-template/new-template',
        element: <BudgetTemplateCreation />
      },
      {
        path: 'budget-template/edit/:id',
        element: <BudgetTemplateEdit />
      }
    ]
  },
  {
    path: 'management',
    element: (
      <AuthGuard>
        <RoleGuard currentModule={MODULES.USERS.id} roles={MODULES.USERS.roles} permissions={MODULES.USERS.permissions}>
          <DashboardLayout />
        </RoleGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'user/list',
        element: <UsersList />
      },
      {
        path: 'user/:id',
        element: <User />
      },
      {
        path: 'client/list',
        element: <ClientsList />
      },
      { path: 'client/:id', element: <Client /> },
      {
        path: 'user/register',
        children: [
          {
            path: 'ami-user',
            element: <Register />
          },
          {
            path: 'client-user',
            element: <Register />
          }
        ]
      },
      { path: 'client/register/ami-client', element: <Register /> }
    ]
  },
  {
    path: 'reports',
    element: (
      <AuthGuard>
        <RoleGuard
          roles={MODULES.REPORTS.roles}
          permissions={MODULES.REPORTS.permissions}
          currentModule={MODULES.REPORTS.id}
        >
          <DashboardLayout />
        </RoleGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Reports />
      },
      {
        path: '/client',
        element: <Quicksight />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
