import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTE_PATH } from 'config/constants';
import useAuth from 'hooks/useAuth';
import RoleGuardContext from 'contexts/RolgeGuardContext';
import { IUser } from 'types/IUser';

interface RoleGuardProps {
  roles: { id: number; name: string }[];
  children: ReactNode;
  permissions: {
    create: (user: IUser) => boolean;
    read: (user: IUser) => boolean;
    update: (user: IUser) => boolean;
    delete: (user: IUser) => boolean;
  };
  currentModule: number;
}

const RoleGuard: FC<RoleGuardProps> = ({ roles, children, permissions, currentModule }) => {
  const { user } = useAuth();
  if (user?.role?.id && roles?.find((role) => role.id === user.role.id) && permissions.read(user)) {
    return <RoleGuardContext.Provider value={{ permissions, currentModule }}>{children}</RoleGuardContext.Provider>;
  }

  return <Navigate to={ROUTE_PATH.HOME} />;
};

RoleGuard.propTypes = {
  roles: PropTypes.array,
  children: PropTypes.node
};

export default RoleGuard;
