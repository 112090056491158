import { AUTHENTICATION_URL } from 'config/endpoints';
import { fetchAPI } from 'services/http/fetch';
import { EMethods, IFetchOptions } from 'services/http/types';
import { IAuthenticationRefreshToken } from 'types/IAuth';
import { REFRESH_DEFAULT_PARAMS, ACCESS_DEFAULT_PARAMS, IRefreshConnectionParams } from 'utils/connection';
import { stringify } from 'querystring';
import { getRefreshToken as getRefreshTokenFromStorage } from 'utils/authentication';

const optionsGetAuthenticationToken: IFetchOptions = {
  method: EMethods.POST,
  URL: AUTHENTICATION_URL,
  auth: false
};

export const getAccessToken = (): Promise<IAuthenticationRefreshToken> => {
  const newOptions: IFetchOptions = {
    ...optionsGetAuthenticationToken,
    body: stringify(ACCESS_DEFAULT_PARAMS),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  return fetchAPI(newOptions);
};

export const getRefreshToken = (): Promise<IAuthenticationRefreshToken> => {
  const params: IRefreshConnectionParams = {
    ...REFRESH_DEFAULT_PARAMS,
    refresh_token: getRefreshTokenFromStorage()
  };
  const newOptions: IFetchOptions = {
    ...optionsGetAuthenticationToken,
    body: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  return fetchAPI(newOptions);
};
