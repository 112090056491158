/* eslint-disable @typescript-eslint/indent */
import { Button, Grid, InputAdornment, TextField, Typography, Box } from '@material-ui/core';
import { Formik } from 'formik';
import { useEventWizardBudget } from 'hooks';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useAuth from 'hooks/useAuth';
import useRoleGuard from 'hooks/useRoleGuard';
import CurrencyDollar from 'icons/CurrencyDollar';
import X from 'icons/X';
import React, { FC } from 'react';
import { EEventBudgetComponent, EEventStatuses, EProfitMarkupType, IManagementFee } from 'types/IEvent';
import { ProfitMarkup } from 'components/widgets/inputs';
import { useSnackbar } from 'notistack';
import Scrollbar from 'components/Scrollbar';
import { onlyNumbersValidor, twoDecimalsMax } from 'utils/regexValidators';
import { addTemplate, updateBudgetTemplate, updateEventBudget as updateEventBudgetFetch } from 'services/http';
import { EBudgetTemplateType } from 'types/IBudget';
import { numberFormatCurrencyDollar, ROUTE_PATH } from 'config/constants';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-useless-path-segments
import './css/index.css';
import { navigationAction, isSignedEvent } from 'components/dashboard/wizard/wizardUtils';

export class ManagementFee implements IManagementFee {
  id = 0;
  eventBudgetManagementFeeComponentId = 0;
  itemDescription = '';
  isAmiExpenses = false;
  isClientExpenses = false;
  quantity = 0;
  perItemFee = 0;
  total = 0;
  managementFee: 0;
  managementFeeType = EProfitMarkupType.PERCENTAJE;
}

const EventWizardManagementFee: FC = () => {
  const { user } = useAuth();
  const { permissions } = useRoleGuard();
  const { enqueueSnackbar } = useSnackbar();
  const {
    eventBudget,
    addManagementFee,
    updateManagementFee,
    handleDeleteManagementFee,
    toggleProfitMarkupManagementFee2,
    name,
    currentBudgetType,
    updateEventBudget,
    budgetTemplateId,
    udpateComponentByKey,
    updateCurrentFinancialOption,
    financialOptions,
    event
  } = useEventWizardBudget();
  const navigate = useNavigate();
  const isEventSigned = isSignedEvent(event);

  const submitAction = async (setSubmitting: Function, optionToNavigate?: string) => {
    if (currentBudgetType === EBudgetTemplateType.CREATE) {
      const payload = {
        eventBudget,
        createdBy: user.id,
        inactive: false,
        name
      };
      const { id } = await addTemplate(payload);
      navigate(`${ROUTE_PATH.DASHBOARD.BUDGET_TEMPLATE.EDIT}/${id}`);
    } else if (currentBudgetType === EBudgetTemplateType.EDIT) {
      const payload = {
        eventBudget,
        createdBy: user.id,
        inactive: false,
        name,
        id: budgetTemplateId
      };
      await updateBudgetTemplate(payload);
      navigationAction({
        optionToNavigate,
        financialOptions,
        eventId: event.id,
        updateCurrentFinancialOption,
        navigate
      });
      setSubmitting(false);
    } else {
      if (event.eventStates[0].status === EEventStatuses.SIGNED) {
        throw Error('Event already has been approved');
      }
      const {
        profit,
        commissions,
        totalExpense,
        programProfit,
        profitMargin,
        totalProgram,
        totalManagementFee,
        totalProgramProfit,
        eventBudgetManagementFee
      } = await updateEventBudgetFetch(eventBudget);
      updateEventBudget({
        ...eventBudget,
        totalExpense,
        totalManagementFee,
        profit,
        commissions,
        programProfit,
        profitMargin,
        totalProgram,
        totalProgramProfit,
        eventBudgetManagementFee
      });
      navigationAction({
        optionToNavigate,
        financialOptions,
        eventId: event.id,
        updateCurrentFinancialOption,
        navigate
      });
      setSubmitting(false);
    }
  };

  const isUserAllowedToEdit = permissions.update(user);

  const getValueExpense = (managementFee) => {
    let result = null;
    if (managementFee.isAmiExpenses && managementFee.isAmiExpenses === true) {
      result = 'isAmiExpenses';
    } else if (managementFee.isClientExpenses && managementFee.isClientExpenses === true) {
      result = 'isClientExpenses';
    }
    return result;
  };

  const getTotal = (eventBudgetParam) => {
    let total = 0;
    if (
      eventBudgetParam &&
      eventBudgetParam.eventBudgetManagementFee &&
      eventBudgetParam.eventBudgetManagementFee.managementFees &&
      Array.isArray(eventBudgetParam.eventBudgetManagementFee.managementFees)
    ) {
      for (let i = 0; i < eventBudgetParam.eventBudgetManagementFee.managementFees.length; i++) {
        if (eventBudgetParam.eventBudgetManagementFee.managementFees[i].isClientExpenses === true) {
          total += eventBudgetParam.eventBudgetManagementFee.managementFees[i].total;
        }
      }
    }
    return total;
  };

  const handleSaveAndQuit = async (setErrors, setStatus, setSubmitting): Promise<void> => {
    if (!isUserAllowedToEdit) return;
    try {
      await submitAction(setSubmitting);
      enqueueSnackbar('Saved', {
        variant: 'success'
      });
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
      enqueueSnackbar(err?.message || 'Error saving the event', { variant: 'error' });
    }
  };

  return (
    <Formik
      initialValues={{
        submit: null
      }}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        if (!isUserAllowedToEdit) return;
        try {
          await submitAction(setSubmitting, 'amiInternalCost');
          enqueueSnackbar('Saved', {
            variant: 'success'
          });
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
          enqueueSnackbar(err?.message || 'Error saving the event', { variant: 'error' });
        }
      }}
    >
      {({ handleSubmit, isSubmitting, setErrors, setStatus, setSubmitting }): JSX.Element => (
        <form onSubmit={handleSubmit}>
          <Scrollbar>
            <Grid sx={{ display: 'flex', alignItems: 'center' }} item md={12} sm={12} xs={12}>
              <Grid item xs={2}>
                <Typography>Managment Fee:</Typography>
              </Grid>
              <Grid item xs={2}>
                <ProfitMarkup
                  handleToogleProfitMarkup={() => toggleProfitMarkupManagementFee2()}
                  value={eventBudget?.eventBudgetManagementFee?.managementFee}
                  profitMarkupType={eventBudget?.eventBudgetManagementFee?.managementFeeType}
                  handleOnChange={(_) => {
                    if (_.target) {
                      udpateComponentByKey({
                        component: EEventBudgetComponent.eventBudgetManagementFee,
                        attribute: null,
                        index: null,
                        key: 'managementFee',
                        value: _.target.value
                      });
                    }
                  }}
                  disabled={isEventSigned}
                />
              </Grid>
            </Grid>
            <Box sx={{ minWidth: 1500, transform: 'scale(0.80) translate(-180px, -10px)' }}>
              <Grid container spacing={2}>
                {eventBudget?.eventBudgetManagementFee?.managementFees.length > 0 && (
                  <>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '12px' }}>Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '12px' }}>Expense</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '12px' }}>Quantity</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '12px' }}>Per Item Fee</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '12px' }}>Total</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {' '}
                    </Grid>
                  </>
                )}
                {eventBudget?.eventBudgetManagementFee?.managementFees?.length &&
                  eventBudget?.eventBudgetManagementFee?.managementFees?.map((managementFee, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          multiline
                          size="small"
                          value={managementFee.itemDescription}
                          rows={2}
                          placeholder="Item Description"
                          variant="outlined"
                          name="itemDescription"
                          onChange={(_) => updateManagementFee('itemDescription', _.target.value, index)}
                          disabled={isEventSigned}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <RadioGroup
                          name="expense"
                          value={getValueExpense(managementFee)}
                          // eslint-disable-next-line @typescript-eslint/no-shadow
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const valueExpense = (event.target as HTMLInputElement).value;
                            if (valueExpense === 'isAmiExpenses') {
                              updateManagementFee('isAmiExpenses', true, index);
                              updateManagementFee('isClientExpenses', false, index);
                            } else if (valueExpense === 'isClientExpenses') {
                              updateManagementFee('isAmiExpenses', false, index);
                              updateManagementFee('isClientExpenses', true, index);
                            }
                          }}
                        >
                          <FormControlLabel
                            value="isAmiExpenses"
                            control={<Radio />}
                            label="Ami Expense"
                            disabled={isEventSigned}
                          />
                          <FormControlLabel
                            value="isClientExpenses"
                            control={<Radio />}
                            label="Client Expense"
                            disabled={isEventSigned}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="Quantity"
                          value={managementFee.quantity}
                          onChange={(_) => {
                            if (!_.target.value || onlyNumbersValidor.test(_.target.value)) {
                              updateManagementFee('quantity', _.target.value, index);
                            }
                          }}
                          disabled={isEventSigned}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="perItemFee"
                          value={managementFee.perItemFee}
                          onChange={(_) => {
                            if (
                              !_.target.value ||
                              (onlyNumbersValidor.test(_.target.value) && twoDecimalsMax.test(_.target.value))
                            ) {
                              updateManagementFee('perItemFee', _.target.value, index);
                            }
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyDollar />
                              </InputAdornment>
                            )
                          }}
                          disabled={isEventSigned}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="total"
                          value={new Intl.NumberFormat('en', numberFormatCurrencyDollar).format(managementFee.total)}
                          disabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <CurrencyDollar />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          onClick={() => handleDeleteManagementFee(index)}
                          variant="text"
                          disabled={isEventSigned}
                        >
                          <X />
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Grid item md={12} sm={12} xs={12}>
                  <Button sx={{ my: 2 }} variant="text" onClick={() => addManagementFee()} disabled={isEventSigned}>
                    + Add Management fee
                  </Button>
                </Grid>
                <Grid sx={{ display: 'flex', alignItems: 'center' }} item md={12} sm={12} xs={12}>
                  <Grid item xs={2}>
                    <Typography>Client Total: </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="total"
                      value={new Intl.NumberFormat('en', numberFormatCurrencyDollar).format(getTotal(eventBudget))}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyDollar />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      navigationAction({
                        optionToNavigate: 'shipping_postage',
                        updateCurrentFinancialOption,
                        navigate,
                        financialOptions,
                        eventId: event.id
                      })
                    }
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isUserAllowedToEdit || isSubmitting || isEventSigned}
                    onClick={() => handleSaveAndQuit(setErrors, setStatus, setSubmitting)}
                    variant="contained"
                    sx={{ m: 2 }}
                  >
                    Save and quit
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isUserAllowedToEdit || isSubmitting || isEventSigned}
                    type="submit"
                    variant="contained"
                    sx={{
                      my: 2
                    }}
                  >
                    Save and continue
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Scrollbar>
        </form>
      )}
    </Formik>
  );
};

export default EventWizardManagementFee;
