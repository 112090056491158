import { fetchAPI } from 'services/http';
import { CLIENTS_ALL_URL, CLIENTS_INACTIVE_URL, CLIENTS_TEMPLATES, CLIENTS_URL } from 'config/endpoints';
import { IClientsResponse, IClientUser } from 'types/IClients';
import { IFetchOptions, EMethods } from './types';

const optionsGetClients: IFetchOptions = {
  method: EMethods.GET,
  URL: CLIENTS_ALL_URL
};

const optionsGetClientInformationById: IFetchOptions = {
  method: EMethods.GET,
  URL: CLIENTS_URL
};

const optionsCreateClient: IFetchOptions = {
  method: EMethods.POST,
  URL: CLIENTS_URL
};

const optionsInactiveClient: IFetchOptions = {
  method: EMethods.DELETE,
  URL: CLIENTS_INACTIVE_URL,
  paramInUrlLine: true
};

const optionsUpdateClient: IFetchOptions = {
  method: EMethods.PUT,
  URL: CLIENTS_URL
};

const inactiveClientById = (params: any): Promise<IClientUser> => {
  const newOptions: IFetchOptions = {
    ...optionsInactiveClient,
    params
  };
  return fetchAPI(newOptions);
};

const createClient = (body: any): Promise<IClientUser> => {
  const newOptions: IFetchOptions = {
    ...optionsCreateClient,
    body
  };
  return fetchAPI(newOptions);
};

const getClients = (params: any): Promise<IClientsResponse> => {
  const newOptions: IFetchOptions = {
    ...optionsGetClients,
    params
  };
  return fetchAPI(newOptions);
};

const getClientInformationById = (params: any): Promise<IClientUser> => {
  const newOptions: IFetchOptions = {
    ...optionsGetClientInformationById,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const updateClient = (body: any): Promise<IClientUser> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateClient,
    body
  };
  return fetchAPI(newOptions);
};

const optionsGetClientsTemplate: IFetchOptions = {
  method: EMethods.GET,
  URL: CLIENTS_TEMPLATES
};

const getClientsTemplate = (): Promise<string[]> => fetchAPI(optionsGetClientsTemplate);

export { getClientInformationById, getClients, createClient, inactiveClientById, updateClient, getClientsTemplate };
