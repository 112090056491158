import { useEffect, useState } from 'react';
import { IUser } from 'types/IUser';
import { getUserInformationById, getUsersByRoleAndClient } from 'services/http';

export const useGetUserById = (userId: number | string): { user: IUser; isLoading: boolean } => {
  const [user, setUser] = useState<IUser>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUser = async () => {
    try {
      setIsLoading(true);
      const response = await getUserInformationById(userId);
      setIsLoading(false);
      setUser(response);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  return { user, isLoading };
};

export const useUsersByRoleAndClient = (
  clientId: string | number,
  roles: string
): { users: IUser[]; isLoading: boolean } => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const response: IUser[] = await getUsersByRoleAndClient(`${clientId}/${roles}`);
      setUsers(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (clientId) getUsers();
  }, [clientId]);
  return { users, isLoading };
};
