import { USER_ROLES } from 'config/endpoints';
import { fetchAPI } from 'services/http';
import { IRoleResponse } from 'types/IRole';
import { EMethods, IFetchOptions } from './types';

interface IRolesParams {
  PageNumber?: number;
  PageSize?: number;
  Type: string;
}

const optionsGetEvents: IFetchOptions = {
  method: EMethods.GET,
  URL: USER_ROLES,
  auth: true
};

const getRolesByUserType = (params?: IRolesParams): Promise<IRoleResponse> => {
  if (params) {
    const optionsWithParams: IFetchOptions = {
      ...optionsGetEvents,
      params
    };
    return fetchAPI(optionsWithParams);
  }
  return fetchAPI(optionsGetEvents);
};

export type { IRolesParams };

export { getRolesByUserType };
