import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';
import { ReactComponent as AMILogo } from 'assets/img/AMILogo.svg';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')();

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot height="52" version="1.1" viewBox="0 0 146 52" width="146" {...props}>
    <AMILogo />
  </LogoRoot>
);

export default Logo;
