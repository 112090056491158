enum EMethods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

interface IFetchOptions {
  method: EMethods;
  URL: string;
  params?: {};
  body?: any;
  headers?: {};
  paramInUrlLine?: boolean;
  auth?: boolean;
}

export { EMethods };

export type { IFetchOptions };
