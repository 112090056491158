const initialData = window.env;
declare global {
  interface Window {
    env: any;
  }
}

export const amplifyConfig = {
  Auth: {
    region: initialData.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: initialData.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: initialData.REACT_APP_AWS_IDENTITY_POOL,
    userPoolWebClientId: initialData.REACT_APP_AWS_CLIENT_ID
  }
};

export const gtmConfig = {
  containerId: initialData.REACT_APP_GTM_CONTAINER_ID
};
