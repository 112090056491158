import { useEffect, useState } from 'react';
import { getBudgetTemplateById } from 'services/http';
import { IBudgetTemplate } from 'types/IBudget';

export const useGetBudgetTemplateById = (templateId): { budgetTemplate: IBudgetTemplate; isLoading: boolean } => {
  const [budgetTemplate, setBudgetTemplate] = useState<IBudgetTemplate>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchGetBudgetTemplate = async () => {
    try {
      setIsLoading(true);
      const response = await getBudgetTemplateById(templateId);
      setBudgetTemplate(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    if (templateId) fetchGetBudgetTemplate();
  }, []);
  return { budgetTemplate, isLoading };
};
