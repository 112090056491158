import { useEffect, useState } from 'react';
import { IClientUser } from 'types/IClients';
import { IUser } from 'types/IUser';
import { getReportClients, getClientInformationById } from 'services/http';

export const useGetClients = (user: IUser): { clients: IClientUser[]; isLoading: boolean } => {
  const [clients, setClients] = useState<IClientUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getClients = async () => {
    try {
      setIsLoading(true);
      const response = await getReportClients({
        userId: user.id
      });
      setClients(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  return { clients, isLoading };
};

export const useGetClientById = (clientId: string | number): { client: IClientUser; isLoading: boolean } => {
  const [client, setClient] = useState<IClientUser>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getClient = async () => {
    try {
      setIsLoading(true);
      const response = await getClientInformationById(clientId);
      setIsLoading(false);
      setClient(response);
    } catch (error) {
      setIsLoading(false);
      throw Error(error);
    }
  };

  useEffect(() => {
    getClient();
  }, []);
  return { client, isLoading };
};
