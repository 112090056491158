import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { ROUTE_PATH } from 'config/constants';
import Logo from '../Logo';
import DashboardSidebarSections from './DashbordSidebarSections';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.default'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to={ROUTE_PATH.HOME}>
              <Logo sx={{ height: 80, width: 80 }} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'grey.100',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to={ROUTE_PATH.DASHBOARD.ACCOUNT}>
              <Avatar
                src={user.picture}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textSecondary" variant="subtitle2">
                {user.name} {user.familyName}
              </Typography>
              <Typography color="textSecondary" variant="body2" style={{ textTransform: 'capitalize' }}>
                Your role: {user?.role?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <DashboardSidebarSections />
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      {location.pathname.includes('wizard') ? (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      ) : (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              onClose={onMobileClose}
              open={openMobile}
              PaperProps={{
                sx: {
                  backgroundColor: 'background.paper',
                  width: 280
                }
              }}
              variant="temporary"
            >
              {content}
            </Drawer>
          </Hidden>
          <Hidden lgDown>
            <Drawer
              anchor="left"
              open
              PaperProps={{
                sx: {
                  backgroundColor: 'background.paper',
                  height: 'calc(100% - 64px) !important',
                  top: '64px !Important',
                  width: 280
                }
              }}
              variant="persistent"
            >
              {content}
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
