import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@material-ui/core';
import { LoginAmplify } from 'components/authentication/login';
import useAuth from 'hooks/useAuth';
import { ROUTE_PATH } from 'config/constants';
import Logo from 'components/Logo';

const Login: FC = () => {
  const { platform } = useAuth() as any;

  return (
    <>
      <Helmet>
        <title>Login | AMI</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'grey.200',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container fixed>
          <Box textAlign="center" sx={{ py: 5 }}>
            <Typography color="textPrimary" gutterBottom variant="h2">
              Welcome to AMI MeetingSoft
            </Typography>
            <Logo sx={{ width: 200, height: '100%' }} />
          </Box>
        </Container>
        <Container maxWidth="sm" sx={{ pb: '80px' }}>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mb: 2,
                  justifyContent: 'center'
                }}
              >
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Sign In
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginAmplify />
              </Box>
              <Divider sx={{ my: 3 }} />
              {platform === 'Amplify' && (
                <Link
                  color="grey.100"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to={ROUTE_PATH.AUTHENTICATION.PASSWORD_MANAGEMENT.RECOVERY}
                  variant="body2"
                >
                  Forgot password
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
